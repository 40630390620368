
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetoshirdiminibus() {



  const cardData =
  {
    keyword: 'Pune to Shirdi Mini Bus Package',
    heading: 'Pune to Shirdi Mini Bus Package: Shraddha Travels',
    headingDescription: 'Are you planning a spiritual getaway from Pune? Look no further! Shraddha Travels offers an unbeatable Pune to Shirdi Mini Bus Package designed to make your journey comfortable and memorable. Shirdi, the revered abode of Sai Baba, attracts thousands of devotees each year. Let us take you on a spiritual journey that goes beyond just visiting temples.',
    top: 'Top Places to Visit in Shirdi with Shraddha Travel',

    topPlaces: [
        {
          title: "1. Sai Baba Samadhi Mandir",
          description: "The main attraction of Shirdi, the Sai Baba Samadhi Mandir is where Sai Baba's tomb is located. This sacred site features stunning architecture with intricate carvings and a serene atmosphere. Visitors can witness the daily aarti (prayer ceremony), which is a deeply spiritual experience filled with devotion and music, drawing thousands of devotees each day."
        },
        {
          title: "2. Dwarkamai Masjid",
          description: "Once the residence of Sai Baba, Dwarkamai Masjid stands as a symbol of his universal love for all religions. The mosque houses the sacred Dhuni (eternal flame) that Baba lit, representing purity and spirituality. Visitors can observe the beautiful structure, adorned with Baba's portraits, and feel the tranquility that envelops this historic site."
        },
        {
          title: "3. Chavadi",
          description: "Chavadi holds great significance for Sai Baba devotees, as it is where he spent alternate nights. This charming space is adorned with beautiful portraits and quotes from Sai Baba, creating a warm and inviting atmosphere. Daily prayers and rituals conducted here allow devotees to connect with Baba's teachings and experience a sense of community."
        },
        {
          title: "4. Lendi Baug",
          description: "Lendi Baug is a peaceful garden where Sai Baba would often retreat for solitude and reflection. The garden features trees planted by him, a beautiful well, and serene pathways perfect for meditation. It offers a tranquil escape from the bustling temple area, allowing visitors to connect with nature and enjoy the calm surroundings."
        },
        {
          title: "5. Shri Saibaba Sansthan Trust",
          description: "This trust is responsible for managing the Sai Baba Temple complex and provides numerous facilities for devotees, including comfortable accommodation, food services, and prasad distribution. The trust's efforts ensure that visitors have a fulfilling and organized experience, promoting Sai Baba's teachings of service and compassion."
        },
        {
          title: "6. Khandoba Mandir",
          description: "Khandoba Mandir is an ancient temple dedicated to Lord Khandoba, located just a short distance from Shirdi. This temple is not only a spiritual site but also a place of cultural significance, attracting local devotees who come to seek blessings and participate in traditional rituals. The vibrant festivals celebrated here add to its allure."
        },
        {
          title: "7. Gurusthan",
          description: "Gurusthan is revered as the place where Sai Baba first appeared to his devotees. It features a sacred neem tree, believed to be a source of spiritual energy, and a small shrine dedicated to Baba. Visitors often come to pay their respects, reflecting on Baba's teachings of love and faith while enjoying the serene ambiance of this historic site."
        },
        {
          title: "8. Shri Sainath Hospital",
          description: "Shri Sainath Hospital is well-known for its excellent medical facilities and compassionate care. As part of the Sai Baba Trust's commitment to community service, this hospital offers quality healthcare to the underprivileged, embodying Sai Baba's message of helping those in need. The hospital is equipped with modern amenities and serves as a beacon of hope for many."
        },
        {
          title: "9. Maharashtra Tourism Development Corporation (MTDC) Resort",
          description: "The MTDC Resort provides a comfortable stay for visitors, with modern amenities and beautiful views of the surrounding landscape. Guests can relax in well-appointed rooms, enjoy delicious local cuisine, and explore nearby attractions. This resort is ideal for those looking to unwind after a day of spiritual exploration in Shirdi."
        },
        {
          title: "10. Samadhi Mandir Museum",
          description: "The Samadhi Mandir Museum offers a fascinating glimpse into the life of Sai Baba through a collection of artifacts and personal belongings. Exhibits include photographs, clothing, and items used by Baba, allowing visitors to gain a deeper understanding of his teachings and the impact he had on countless lives. It's a must-visit for anyone wishing to learn more about this revered saint."
        }
      ],
      services: [
        {
          name: "Pune to Shirdi Mini Bus on Rent",
          description: "Experience hassle-free travel with our well-maintained mini buses available for rent from Pune to Shirdi. Enjoy a comfortable ride equipped with spacious seating and air conditioning, designed to make your journey to the sacred Sai Baba Temple as pleasant as possible. Our experienced drivers will navigate the route, allowing you to relax and take in the scenic views along the way."
        },
        {
          name: "Pune to Shirdi Mini Bus Package",
          description: "Looking for a seamless and hassle-free trip to Shirdi? Our Pune to Shirdi Mini Bus Package offers the perfect solution for your pilgrimage. We provide comfortable, air-conditioned mini buses equipped with modern amenities, ensuring a relaxing and enjoyable journey. Our comprehensive package includes transportation and itinerary planning, tailored to meet your needs. Whether you're traveling with family, friends, or a group, our Pune to Shirdi Mini Bus Package ensures a smooth and spiritual experience. Book with us for a memorable trip to the sacred Sai Baba Temple."
        },
        {
          name: "Pune Airport to Shirdi Mini Bus on Rent",
          description: "Arriving at Pune Airport? We provide convenient mini bus services that connect directly from the airport to Shirdi. Skip the stress of finding transportation amidst your travel, as our dedicated drivers will be waiting to greet you and ensure you are taken directly to your spiritual destination comfortably. With our service, your journey starts smoothly, allowing you to focus on your pilgrimage."
        },
        {
          name: "Pune to Shirdi Mini Bus AC 13 & 17 Seater",
          description: "Choose between our 13-seater and 17-seater air-conditioned mini buses for your journey to Shirdi. These vehicles are designed for comfort, providing ample space for both passengers and luggage. Travel in a relaxed environment with comfortable seating, ensuring a pleasant experience for family or friends. Each bus is equipped with modern amenities to enhance your travel experience."
        },
        {
          name: "Pune to Shirdi Mini Bus Rent",
          description: "Our competitive rates make it easy for you to rent a mini bus for your trip to Shirdi. Enjoy transparent pricing with no hidden fees, ensuring that you can budget for your journey without any surprises. Our goal is to provide value for your money while offering a reliable and enjoyable travel experience. Let us handle the logistics while you focus on your spiritual journey."
        },
        {
          name: "Bus Hire for Pune to Shirdi",
          description: "Need a larger vehicle for your group? We offer bus hire services that cater to larger groups, ensuring everyone can travel together to Shirdi. Our buses are spacious and comfortable, allowing you to relax during the journey. Perfect for family gatherings, corporate trips, or community events, our service simplifies group travel while maintaining high standards of comfort and safety."
        },
        {
          name: "Pune Airport to Shirdi Mini Bus",
          description: "Our dedicated services connect Pune Airport to Shirdi, making it easy for travelers arriving by air to reach their spiritual destination comfortably. With reliable transportation options, you can rest assured that you will arrive on time. Our friendly drivers will assist you with your luggage, ensuring a smooth transition from air travel to road travel."
        },
        {
          name: "Pune to Shirdi 14-Seater Bus on Rent",
          description: "Ideal for medium-sized groups, our 14-seater buses provide a cozy environment for your journey to Shirdi. These buses are equipped with all necessary amenities, ensuring a comfortable ride. With our focus on safety and comfort, you can enjoy the journey while sharing moments with your group, making your trip even more memorable."
        },
        {
          name: "Pune to Shirdi 17-Seater Bus on Rent",
          description: "Our 17-seater buses are perfect for larger gatherings, allowing your entire group to travel together without the hassle of multiple vehicles. Designed with spacious interiors and comfortable seating, these buses ensure a smooth ride. Whether it’s a family trip or a group pilgrimage, everyone can enjoy the experience together, fostering camaraderie and shared memories."
        },
        {
          name: "17-Seater Mini Bus on Rent in Pune",
          description: "Available for various destinations, including Shirdi, our 17-seater mini buses are spacious and comfortable, designed to accommodate your needs. Equipped with modern amenities like air conditioning and entertainment systems, these vehicles are perfect for longer journeys, ensuring a pleasant travel experience. Our commitment to customer satisfaction means you can travel with peace of mind."
        },
        {
          name: "Pune to Shirdi Mini Bus Package",
          description: "We offer comprehensive mini bus packages that include transportation and itinerary planning for your visit to Shirdi. Enjoy a seamless travel experience from start to finish, with everything arranged for your convenience. Our packages can be customized to suit your needs, ensuring that your spiritual journey is as fulfilling and organized as possible."
        },
        {
          name: "Pune to Shirdi Mini Bus Package Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Shirdi Mini Bus Package services. Our dedicated team is committed to ensuring a smooth and enjoyable ride for all our customers. We pride ourselves on our customer service, so feel free to reach out for any inquiries or special requests to make your trip memorable."
        }
      ],         
      tableData: [
        ['- Pune to Shirdi Mini Bus on Rent', '- Pune Airport to Shirdi Mini Bus on Rent'],
        ['- Pune to Shirdi Mini Bus AC 13 & 17 Seater', '- Pune to Shirdi Mini Bus on Rent'],
        ['- Pune to Shirdi Mini Bus Rent', '- Bus Hire for Pune to Shirdi'],
        ['- Pune Airport to Shirdi Mini Bus', '- Pune to Shirdi Mini Bus Package'],
        ['- Pune Airport to Shirdi Mini Bus', '- Pune to Shirdi 14-Seater Bus on Rent'],
        ['- Pune to Shirdi 17-Seater Bus on Rent', '- 17-Seater Mini Bus on Rent in Pune']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels' Pune to Shirdi Mini Bus Package?",
          WhyChoosedescription: "At Shraddha Travels, we understand the significance of your pilgrimage to Shirdi, a journey steeped in devotion and spirituality. Our goal is to ensure that every aspect of your travel is as comfortable and seamless as possible, allowing you to focus on your spiritual experience. We prioritize your needs and preferences, making your trip memorable and stress-free."
        },
        {
          WhyChooseheading: "Comfortable and Spacious Vehicles:",
          WhyChoosedescription: "Our mini buses are designed with your comfort in mind. Equipped with plush seating, air conditioning, and ample luggage space, we ensure that you can relax throughout your journey. Whether you're traveling with family or friends, our vehicles provide an inviting atmosphere that makes every mile enjoyable, allowing you to unwind and enjoy the scenery."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our professional drivers are not only well-trained and courteous but also possess extensive knowledge of the best routes to Shirdi. They prioritize your safety and comfort, navigating traffic efficiently while allowing you to sit back and relax. With their friendly demeanor, our drivers enhance your travel experience, making it more enjoyable and stress-free."
        },
        {
          WhyChooseheading: "Flexible Packages:",
          WhyChoosedescription: "We understand that each traveler has unique needs. Our customizable packages cater to solo travelers, families, and large groups alike. You can tailor your package according to your preferences, including travel times, stops, and other amenities. This flexibility ensures that your journey aligns perfectly with your spiritual goals and personal requirements."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "At Shraddha Travels, we believe in providing quality service at competitive rates. Our transparent pricing model has no hidden charges, ensuring that you receive excellent value for your money. We strive to make pilgrimage travel accessible, allowing you to focus on your spiritual experience without worrying about expenses."
        },
        {
          WhyChooseheading: "Convenient Booking Process:",
          WhyChoosedescription: "Our user-friendly booking system simplifies the reservation process. You can easily reserve your mini bus package online or contact our responsive customer service team for personalized assistance. We guide you through the booking steps, ensuring a hassle-free experience from the very start."
        },
        {
          WhyChooseheading: "Timely Services:",
          WhyChoosedescription: "Punctuality is one of our core values. We ensure that you arrive at your destination on time by planning efficient routes and keeping track of traffic conditions. You can trust us to honor your schedule, allowing you to focus on your pilgrimage without any concerns about delays."
        },
        {
          WhyChooseheading: "Customer Support:",
          WhyChoosedescription: "Our dedicated customer service team is available around the clock to assist you throughout your journey. Whether you have questions about your itinerary, need assistance during your trip, or require last-minute adjustments, we are just a call away. Your satisfaction is our top priority, and we strive to provide a supportive and reassuring travel experience."
        }
      ]
      
  }


  const faqData = [
    {
      question: "How do I book the Pune to Shirdi Mini Bus Package?",
      answer: "You can book your mini bus package through our website or by contacting our customer service team. Simply provide your travel dates, group size, and any specific requirements, and we’ll assist you in securing your reservation."
    },
    {
      question: "What type of vehicles do you offer?",
      answer: "We offer a range of mini buses, including 13-seater and 17-seater options. All our vehicles are equipped with air conditioning and comfortable seating to ensure a pleasant journey."
    },
    {
      question: "Is there a driver included in the package?",
      answer: "Yes, every mini bus package includes a professional driver who will ensure your safety and comfort during the journey, allowing you to relax and enjoy the ride."
    },
    {
      question: "Can I customize my itinerary?",
      answer: "Absolutely! We offer flexible packages that allow you to customize your itinerary based on your needs and preferences, ensuring your trip is tailored just for you."
    },
    {
      question: "Are there any hidden charges?",
      answer: "No, we believe in transparency. Our pricing is straightforward, with no hidden charges. You will be informed of the total cost during the booking process, so there are no surprises."
    },
    {
      question: "What if I need to cancel or change my booking?",
      answer: "Please contact our customer service team as soon as possible. Our cancellation and modification policies will be explained at the time of booking, allowing you to understand your options."
    },
    {
      question: "How long does the journey from Pune to Shirdi take?",
      answer: "The journey typically takes around 4 to 5 hours, depending on traffic and road conditions. We strive to ensure a smooth and timely journey."
    },
    {
      question: "Do you offer pickup and drop-off services from Pune Airport?",
      answer: "Yes, we provide pickup and drop-off services from Pune Airport to Shirdi as part of our mini bus package. Just let us know your requirements when booking, and we’ll take care of the rest."
    },
    {
      question: "Can I hire the mini bus for a round trip?",
      answer: "Yes, our packages are available for both one-way and round-trip journeys. You can choose the option that suits you best, depending on your travel plans."
    },
    {
      question: "What should I do if I have more questions?",
      answer: "Feel free to reach out to our customer service team via phone or email. We are here to assist you with any inquiries you may have and ensure you have a pleasant travel experience."
    }
  ];
  

  const testimonials = [
    {
      name: "Mr. Rajesh Patil",
      text: "I recently traveled with Shraddha Travels for a pilgrimage to Shirdi, and I couldn't be more satisfied with the service! The mini bus was spacious and comfortable, making the long journey pleasant for our family. Our driver was professional and friendly, ensuring we reached our destination safely and on time. The entire booking process was hassle-free, and the price was very reasonable for the quality of service provided. I highly recommend Shraddha Travels for anyone looking to make their journey to Shirdi a memorable one!"
    },
    {
      name: "Miss Sneha Deshmukh",
      text: "We booked the Pune to Shirdi Mini Bus Package for a group of friends, and it was a fantastic experience! The mini bus was clean and well-maintained, and the air conditioning was a blessing in the summer heat. Our driver was punctual and knew the best routes, which helped us avoid traffic. We enjoyed the entire trip, and the affordable pricing was just the cherry on top. Shraddha Travels made our pilgrimage enjoyable and stress-free. We will definitely choose them for our future travels!"
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Shirdi Mini Bus Package Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Shirdi Mini Bus Package | Comfortable Group Travel</title>
    <meta name="description" content="Explore our Pune to Shirdi Mini Bus Package. Rent AC mini buses for a comfortable journey to Shirdi from Pune." />
    <meta name="keywords" content="Pune to Shirdi Mini Bus, Mini Bus Rent Pune to Shirdi, Shirdi Mini Bus Package, 13 & 17 Seater Mini Bus Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Shirdi Mini Bus Package | Comfortable Group Travel" />
    <meta property="og:description" content="Rent our AC mini buses for a seamless trip from Pune to Shirdi. Ideal for family and group travel." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-shirdi-mini-bus-package" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/shirdi-mini-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Shirdi Mini Bus Package",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-shirdi-mini-bus-package",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Mini Bus Package from Pune to Shirdi",
                    "description": "Comfortable and affordable mini bus package from Pune to Shirdi. Options for 13 and 17 seater AC buses available."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -02.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Pune to Shirdi Mini Bus Package - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoshirdiminibus;