
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Jyotilinga() {



  const cardData =
  {
    keyword: ' 12 Jyotirlinga Darshan Tour Package ',
    heading: 'Shraddha Travels: 12 Jyotirlinga Darshan Tour Package ',
    headingDescription: 'Embark on a divine journey with Shraddha Travels as we take you on an unparalleled pilgrimage to visit the 12 Jyotirlingas—sacred shrines dedicated to Lord Shiva, revered by devotees across India. Our comprehensive Jyotirlinga Darshan tour package from Pune is designed to provide you with a spiritually enriching experience, combining comfort, convenience, and devotion.',
    top: 'Top Places to Visit in the 12 Jyotirlinga Darshan Tour Package from Pune',

    topPlaces: [
        {
            title: "1. Somnath Temple (Gujarat)",
            description: "The Somnath Temple, located in Prabhas Patan near Veraval, is the first of the twelve Jyotirlingas. This magnificent temple is renowned not only for its stunning architectural beauty but also for its deep historical significance, having been reconstructed several times after destruction. Dedicated to Lord Shiva, it attracts millions of devotees and tourists alike, symbolizing faith and resilience."
        },
        {
            title: "2. Mallikarjuna Temple (Andhra Pradesh)",
            description: "Nestled on the serene Shri Shaila Mountain, the Mallikarjuna Temple is dedicated to Lord Shiva and his consort Parvati. It is one of the most revered Jyotirlingas in India, famous for its tranquil environment and spiritual ambiance. The temple's architecture reflects traditional South Indian style, and the surrounding hills offer breathtaking views, making it a perfect pilgrimage destination."
        },
        {
            title: "3. Mahakaleshwar Temple (Madhya Pradesh)",
            description: "Situated in the ancient city of Ujjain, the Mahakaleshwar Temple is notable for its unique idol of Lord Shiva, believed to be self-manifested. The temple is a major pilgrimage site, especially during the Kumbh Mela. Visitors come to witness the mesmerizing Bhasma Aarti, an elaborate ritual involving sacred ashes, creating a profound spiritual experience amidst the bustling atmosphere."
        },
        {
            title: "4. Omkareshwar Temple (Madhya Pradesh)",
            description: "Located on the picturesque Mandhata Island in the Kaveri River, the Omkareshwar Temple is renowned for its distinctive Om-shaped land formation. This Jyotirlinga is known for its serene and spiritual surroundings, attracting pilgrims who seek peace and enlightenment. The temple's architecture features intricate carvings and a beautiful setting, providing a perfect backdrop for reflection and devotion."
        },
        {
            title: "5. Kedarnath Temple (Uttarakhand)",
            description: "Perched high in the majestic Himalayas, the Kedarnath Temple is one of the most significant Jyotirlingas and a key pilgrimage site in Hinduism. Accessible only by foot or helicopter, the temple's remote location enhances its mystical allure. Surrounded by snow-capped peaks and breathtaking landscapes, the journey to Kedarnath is as much about the experience as the destination itself, offering tranquility and a deep connection to nature."
        },
        {
            title: "6. Bhimashankar Temple (Maharashtra)",
            description: "Situated in the lush Sahyadri hills near Pune, the Bhimashankar Temple is the sixth Jyotirlinga and a haven for nature lovers. The temple is surrounded by dense forests and wildlife, making it a perfect spot for both spiritual seekers and adventure enthusiasts. The ancient architecture and the tranquil environment provide a serene escape from the city's hustle and bustle."
        },
        {
            title: "7. Kashi Vishwanath Temple (Uttar Pradesh)",
            description: "The Kashi Vishwanath Temple, located on the banks of the sacred Ganges River in Varanasi, is one of the holiest Jyotirlingas in India. Known for its vibrant spiritual atmosphere, the temple draws millions of pilgrims who come to pay their respects and seek blessings. The intricate architecture, combined with the river's holy waters, makes it a must-visit for anyone seeking spiritual enlightenment."
        },
        {
            title: "8. Rameswar Temple (Tamil Nadu)",
            description: "The Rameswar Temple in Rameswaram is not only a prominent Jyotirlinga but also a significant site in Hindu mythology. Famous for its grand corridors and towering gopurams, the temple plays a crucial role in the Ramayana epic. Its architectural brilliance and spiritual significance make it a pilgrimage site that captivates the hearts of devotees from all over the country."
        },
        {
            title: "9. Grishneshwar Temple (Maharashtra)",
            description: "Located near the renowned Ellora Caves, the Grishneshwar Temple is celebrated for its stunning architecture and intricate carvings. This Jyotirlinga holds great historical significance and is an integral part of the Ellora cultural heritage. The temple attracts art enthusiasts and spiritual seekers, making it a perfect blend of history, art, and devotion."
        },
        {
            title: "10. Aundha Nagnath Temple (Haryana)",
            description: "The Aundha Nagnath Temple, nestled in the village of Aundha, is a lesser-known Jyotirlinga yet holds immense significance for its devotees. Known for its unique architecture and tranquil surroundings, the temple reflects the rich cultural heritage of the region. Its historical importance and spiritual allure make it a hidden gem worth exploring for those seeking a peaceful pilgrimage experience."
        }
    ],    
    services: [
        {
            name: "Pune to Rameshwaram Darshan Bus Hire",
            description: "Discover the divine beauty of Rameshwaram with our specialized bus hire services. Enjoy a comfortable and hassle-free journey from Pune to this sacred site with Shraddha Travels. Our fleet is equipped for long-distance travel, ensuring a smooth ride as you explore the spiritual significance of Rameshwaram."
        },
        {
          "name": "12 Jyotirlinga Darshan Tour Package",
          "description": "Embark on a divine journey with Shraddha Travels' 12 Jyotirlinga Darshan Tour Package from Pune. This meticulously planned pilgrimage tour takes you to the 12 sacred Jyotirlingas, offering a seamless and comfortable travel experience. Our expert team handles all logistics, ensuring you visit each revered temple with ease, providing a memorable spiritual experience. From the serene ambiance of Somnath to the historic Kashi Vishwanath, each stop promises peace and devotion. Travel in comfort with our well-maintained buses, guided by experienced drivers to ensure a safe and smooth journey throughout your pilgrimage."
        },
        {
            name: "Pune to Trimbakeshwar Bus Hire",
            description: "Experience the spiritual ambiance of Trimbakeshwar with Shraddha Travels' bus hire service. Travel from Pune to this revered Jyotirlinga temple in comfort and style. Our well-maintained buses provide an ideal setting for a serene pilgrimage experience."
        },
        {
            name: "Pune to Vaijnath Jyotirlinga Darshan Bus Hire",
            description: "Plan your pilgrimage to Vaijnath Jyotirlinga with ease. Shraddha Travels offers dedicated bus hire services from Pune to this holy site, ensuring a comfortable and convenient travel experience for all your spiritual needs."
        },
        {
            name: "Pune to Grishneshwar Bus Hire",
            description: "Visit the Grishneshwar Jyotirlinga with our reliable bus hire services. From Pune to Ellora, our buses guarantee a smooth and comfortable journey for your spiritual trip, allowing you to focus on your devotion."
        },
        {
            name: "Pune to Somnath Jyotirlinga Gujarat Bus Hire",
            description: "Embark on a divine journey to Somnath in Gujarat with Shraddha Travels. Our bus hire service from Pune to this historic Jyotirlinga offers a comfortable ride and the opportunity to explore one of the most significant temples in India."
        },
        {
            name: "Pune to Mallikarjuna Jyotirlinga Darshan Bus Hire",
            description: "Travel to Mallikarjuna Jyotirlinga with ease and comfort. Shraddha Travels provides dedicated bus hire services from Pune to this revered temple in Andhra Pradesh, ensuring a serene pilgrimage experience."
        },
        {
            name: "Pune to Mahakaleshwar Ujjain Bus Hire",
            description: "Experience the spiritual aura of Mahakaleshwar in Ujjain with our specialized bus hire services. Travel comfortably from Pune to this significant Jyotirlinga temple, known for its unique rituals and historical importance."
        },
        {
            name: "Pune to Omkareshwar Bus Hire",
            description: "Explore the sacred Omkareshwar temple with Shraddha Travels' bus hire services. Travel comfortably from Pune to Mandhata Island, where this Jyotirlinga is located, and immerse yourself in a divine experience."
        },
        {
            name: "Pune to Bhimashankar Jyotirlinga Bus Hire",
            description: "Visit the Bhimashankar Jyotirlinga with ease using our bus hire services. From Pune to this scenic temple in the Sahyadri hills, Shraddha Travels ensures a smooth and comfortable journey for your pilgrimage."
        },
        {
            name: "Pune to Kashi Vishwanath Temple Bus Hire",
            description: "Travel to the renowned Kashi Vishwanath Temple in Varanasi with Shraddha Travels' dedicated bus hire services. Enjoy a comfortable ride from Pune to this sacred site, known for its spiritual significance and historical charm."
        },
        {
            name: "Pune to Kedarnath Bus Hire",
            description: "Embark on a divine journey to Kedarnath with our specialized bus hire services. From Pune to this high-altitude Jyotirlinga temple, Shraddha Travels ensures a comfortable and safe travel experience for your spiritual quest."
        },
        {
            name: "Pune to Nageshwar Jyotirlinga Bus Hire",
            description: "Visit the sacred Nageshwar Jyotirlinga with Shraddha Travels' bus hire services. Our buses provide a comfortable and convenient travel option from Pune to Dwarka, where this revered temple is located."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "For all your bus hire needs, Shraddha Travels offers top-notch services from Pune. Whether you're traveling to Jyotirlingas or other sacred sites, our fleet of well-maintained buses ensures a comfortable and reliable journey. Contact us today to book your bus hire and experience hassle-free travel."
        },      
        {
            name: "12 Jyotirlinga Darshan Tour Package from Pune",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 12 Jyotirlinga Darshan Tour Package from Pune. We ensure a smooth and enjoyable ride for all our customers, making your spiritual journey memorable."
        }
    ],
    
    tableData: [
        ['- Pune to Rameshwaram Darshan Bus Hire on Rent', '- Pune to Trimbakeshwar Bus Hire On Rent'],
        ['- Pune to Vaijnath Jyotirlinga Darshan Bus Hire', '- Pune to Grishneshwar Bus Hire On Rent'],
        ['- Pune to Somnath Jyotirlinga Gujarat Bus On Rent', '- Pune to Mallikarjuna Jyotirlinga Darshan Bus On Rent'],
        ['- Pune to Mahakaleshwar Ujjain Bus On Rent', '- Pune to Omkareshwar Bus on Rent'],
        ['- Pune to Bhimashankar Jyotirlinga Bus Hire on Rent', '- Pune to Kashi Vishwanath Temple Bus Hire on Rent'],
        ['- Pune to Kedarnath Bus Hire on Rent', '- Pune to Nageshwar Jyotirlinga Bus Hire on Rent'],
        ['- Shraddha Travels Pune On Rent', '']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for the 12 Jyotirlinga Darshan Tour?",
            WhyChoosedescription: "Our tour package covers all 12 Jyotirlingas, ensuring a complete and immersive pilgrimage experience. We provide a well-planned itinerary that allows you to visit each site with ample time for prayer and reflection. Each stop is carefully chosen to enrich your spiritual journey, offering you the chance to connect deeply with the divine."
        },
        {
            WhyChooseheading: "Comfortable Travel:",
            WhyChoosedescription: "Travel in comfort with our well-maintained vehicles, equipped with modern amenities like reclining seats, air conditioning, and entertainment options. Our professional drivers and guides ensure a smooth and pleasant journey throughout the tour, allowing you to relax and focus on your spiritual experience without the stress of navigating roads."
        },
        {
            WhyChooseheading: "Experienced Guides:",
            WhyChoosedescription: "Our knowledgeable guides provide insightful information about each Jyotirlinga, its historical significance, and spiritual importance. They share captivating stories and local legends that enhance your understanding and appreciation of each site. With their expertise, your pilgrimage will be both enlightening and memorable."
        },
        {
            WhyChooseheading: "Customized Packages:",
            WhyChoosedescription: "We offer flexible tour packages that can be customized based on your preferences and requirements. Whether you prefer a more leisurely pace to fully immerse yourself in each location or a more detailed exploration of the rituals and history, we tailor the tour to suit your needs. Your journey is designed for your comfort and satisfaction."
        },
        {
            WhyChooseheading: "Accommodation and Meals:",
            WhyChoosedescription: "Enjoy comfortable accommodation at selected hotels, chosen for their hospitality and convenience. Savor delicious meals during the journey, with options that cater to various dietary preferences. We ensure that all your needs are met, allowing you to focus on your spiritual journey without worrying about logistics."
        },
        {
            WhyChooseheading: "Safety and Hygiene:",
            WhyChoosedescription: "We prioritize your safety and well-being by adhering to strict hygiene and safety protocols. Our vehicles are regularly cleaned and sanitized, and we follow all health guidelines during your travel. We ensure that your experience is not only spiritually fulfilling but also safe and comfortable."
        }
    ]
    
  }


  const faqData = [
    {
        question: "How long is the 12 Jyotirlinga Darshan tour from Pune?",
        answer: "The duration of the tour typically spans around 10-12 days, covering all 12 Jyotirlingas. This allows ample time at each site for prayer, reflection, and exploration."
    },
    {
        question: "What is included in the tour package?",
        answer: "Our package includes transportation, accommodation, meals, entry fees to temples, and guided tours. A detailed itinerary is provided to ensure you know what to expect."
    },
    {
        question: "Can the tour be customized?",
        answer: "Yes, we offer customizable tour packages to accommodate your preferences. You can discuss your specific requirements with us, and we will tailor the tour to suit your needs."
    },
    {
        question: "Are there any specific health or travel advisories for the tour?",
        answer: "We provide updated travel advisories and health guidelines in accordance with the latest government regulations. It's advisable to consult with your healthcare provider before undertaking the pilgrimage."
    },
    {
        question: "How do I book the tour?",
        answer: "You can book the tour by contacting us via phone, email, or through our website. Our team will assist you with the booking process and provide all necessary information."
    },
    {
        question: "What is the cancellation policy?",
        answer: "Our cancellation policy varies depending on the tour package and booking conditions. For detailed information, please refer to our terms and conditions or contact us directly."
    },
    {
        question: "Are there any specific clothing requirements for visiting the temples?",
        answer: "It is recommended to wear modest clothing when visiting temples. Comfortable and appropriate attire is essential for maintaining decorum during your pilgrimage."
    },
    {
        question: "Can I extend the tour or add additional destinations?",
        answer: "Yes, we offer options to extend the tour or add additional destinations based on your preferences. Discuss your requirements with us, and we will make the necessary arrangements."
    },
    {
        question: "What should I bring for the tour?",
        answer: "It's advisable to bring essential items such as comfortable clothing, personal medications, travel documents, and any religious items you wish to carry for the pilgrimage."
    },
    {
        question: "Is there any special assistance available for elderly or differently-abled travelers?",
        answer: "We provide special assistance for elderly and differently-abled travelers. Please inform us in advance so that we can make the necessary arrangements to ensure a comfortable experience."
    }
];



const testimonials = [
    {
        name: "Mr. Rajesh Patil",
        text: "We recently completed the 12 Jyotirlinga Darshan tour package with Shraddha Travels, and it was an incredible experience. The tour was meticulously organized, and every detail was taken care of. The bus was comfortable, and the driver was highly professional and knowledgeable about the routes. We were able to visit all the Jyotirlingas with ease and comfort. The entire journey was spiritually enriching and hassle-free. I highly recommend Shraddha Travels for anyone looking to embark on a similar spiritual journey from Pune!"
    },
    {
        name: "Miss Neha Deshmukh",
        text: "Our family took the 12 Jyotirlinga Darshan tour package from Pune organized by Shraddha Travels, and it exceeded our expectations. The itinerary was well-planned, and the accommodation arrangements were excellent. The bus was clean and comfortable, which made the long journey enjoyable. The staff was attentive and provided great service throughout the trip. It was a memorable spiritual journey that we will cherish forever. Thank you, Shraddha Travels, for such a fantastic experience!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 12 Jyotirlinga Darshan Tour Package from Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>12 Jyotirlinga Darshan Tour Package from Pune | Contact: +91 8087847191 | Shraddha Travels | Affordable Journeys</title>
    <meta name="description" content="Join our 12 Jyotirlinga Darshan Tour Package from Pune. Visit sacred temples with comfortable bus rentals and experienced drivers." />
    <meta name="keywords" content="12 Jyotirlinga Darshan Tour Package, Pune to Rameshwaram, Trimbakeshwar Bus Hire, Jyotirlinga Darshan, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 12 Jyotirlinga Darshan Tour Package from Pune | Affordable Journeys" />
    <meta property="og:description" content="Explore our 12 Jyotirlinga Darshan Tour Package from Pune. Comfortable bus rentals to visit all sacred Jyotirlingas." />
    <meta property="og:url" content="https://www.shraddhatravels.com/jyotirlinga-tour-package" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/jyotirlinga-tour.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "12 Jyotirlinga Darshan Tour Package",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/jyotirlinga-tour-package",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "12 Jyotirlinga Darshan Tour Package from Pune",
                    "description": "Experience a spiritual journey with our 12 Jyotirlinga Darshan Tour Package from Pune. Comfortable buses and expert drivers for your convenience."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-04.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) – 12 Jyotirlinga Darshan Tour Package</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Jyotilinga;