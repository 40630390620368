
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seater20onrent() {



  const cardData =
  {
    keyword: ' 20-Seater Bus on Rent in Pune',
    heading: 'Shraddha Travels: 20 Seater Bus on Rent in Pune',
    headingDescription: 'When it comes to group travel in Pune, Shraddha Travels offers the perfect solution with our premium 20 seater buses on rent. Whether you’re planning a corporate outing, a family gathering, or a school trip, our fleet of well-maintained 20-seater buses ensures a seamless and comfortable travel experience. With a reputation for providing reliable transport services across Pune and nearby areas, Shraddha Travels is the trusted choice for group travel.',
    top: 'Top Reasons to Rent a 20 Seater Bus from Shraddha Travels in Pune',

    topPlaces: [
      {
        title: "1. Spacious Accommodation",
        description: "Our 20-seater bus is thoughtfully designed to comfortably accommodate larger groups, ensuring that everyone can travel together in style. Whether you're planning a family reunion, a corporate outing, or a school trip, you’ll have ample space to relax, socialize, and enjoy the journey together. The spacious layout allows for easy movement and makes it perfect for any group size."
      },
      {
        title: "2. Cost-Effective Group Travel",
        description: "Renting a single bus is often far more economical than arranging multiple vehicles for your group. You’ll save significantly on costs related to fuel, tolls, parking fees, and potential driver expenses. This cost-effective option allows you to allocate your budget towards other enjoyable activities during your trip, making it a smart choice for budget-conscious travelers."
      },
      {
        title: "3. Comfortable Seating",
        description: "Our buses feature well-cushioned, ergonomic seating designed with passenger comfort in mind. Each seat is equipped with generous legroom, allowing passengers to stretch out and relax, even during longer journeys. Whether you’re enjoying scenic views or taking a nap, you can be assured of a comfortable ride throughout your trip."
      },
      {
        title: "4. Experienced Drivers",
        description: "Safety is our top priority, and our drivers reflect that commitment. Professionally trained and possessing extensive experience, our drivers are well-versed in local and outstation routes. Their familiarity with the best travel paths ensures a smooth and safe travel experience, while their friendly demeanor helps create a positive atmosphere on board."
      },
      {
        title: "5. Flexible Itineraries",
        description: "Understanding that every group has unique needs, we offer customizable itineraries that cater to your preferences. Whether you want to explore local attractions, visit historical sites, or embark on adventurous day trips, our flexible options allow you to tailor your travel experience. Work with us to create a memorable journey that suits your group's interests."
      },
      {
        title: "6. Well-Maintained Fleet",
        description: "We take pride in our well-maintained fleet of buses, where safety and reliability are paramount. Each vehicle undergoes regular maintenance and thorough inspections to ensure they meet the highest safety standards. This commitment to upkeep guarantees that you’ll travel in a safe and dependable bus, giving you peace of mind on your journey."
      },
      {
        title: "7. Onboard Amenities",
        description: "Our 20-seater buses are equipped with modern amenities designed to enhance your travel experience. Enjoy features like air conditioning to keep you cool, entertainment systems for movies or music, charging ports for your devices, and spacious luggage compartments for all your belongings. These thoughtful touches make your journey enjoyable and convenient."
      },
      {
        title: "8. 24/7 Customer Support",
        description: "We pride ourselves on offering exceptional customer service. Our dedicated support team is available around the clock to assist with any inquiries or changes to your booking. Whether you need help during your trip or have questions before you book, our responsive team is always here for you, ensuring a seamless travel experience."
      },
      {
        title: "9. Positive Reputation",
        description: "Shraddha Travels has built a strong reputation in Pune for providing reliable and quality service. Our commitment to excellence is reflected in the numerous satisfied customers who frequently return for their travel needs. We take pride in our positive reviews and word-of-mouth recommendations, which showcase our dedication to ensuring every journey is a success."
      },
      {
        title: "10. Hassle-Free Booking Process",
        description: "Our user-friendly online booking system makes reserving your 20-seater bus quick and easy. With just a few clicks, you can secure your transportation, allowing you to focus on planning the fun aspects of your trip. We strive to eliminate unnecessary stress, providing you with a smooth booking experience from start to finish."
      }
    ],
    
    
    services: [
      {
        name: "20 Seater Bus on Rent in Pune",
        description: "Looking for a 20-seater bus on rent in Pune? Our 20-seater buses are perfect for group travel, offering ample space and comfort for various occasions. Whether you're planning a family outing, a corporate event, or a group trip, our buses provide a reliable and enjoyable transportation solution, ensuring a pleasant travel experience."
      },
      {
        name: "20 Seater Bus Rental in Pune for Outstation",
        description: "Planning an outstation trip with a group? Our 20-seater bus rental services are ideal for journeys beyond Pune. With comfortable seating and modern amenities, our buses ensure a pleasant travel experience whether you're heading to distant destinations or exploring new places with friends or family."
      },
      {
        name: "20 Seater Bus Rental in Pimpri Chinchwad",
        description: "In need of a 20-seater bus in Pimpri Chinchwad? We offer convenient and affordable bus rental services in this area. Our well-maintained 20-seater buses are designed to provide a comfortable and enjoyable travel experience for various occasions, whether it's a family gathering or a corporate event."
      },
      {
        name: "20 Seater Mini Bus Hire in Pimpri Chinchwad",
        description: "For those seeking a mini bus hire in Pimpri Chinchwad, our 20-seater mini buses are the perfect choice. Offering flexibility and comfort, our mini buses are ideal for both local and outstation travel, ensuring a smooth and enjoyable journey for all passengers."
      },
      {
        name: "Pune to Mumbai Airport Mini Bus on Rent",
        description: "Traveling from Pune to Mumbai Airport? Our mini bus rental service provides a comfortable and efficient solution for airport transfers. With spacious seating and reliable service, our mini buses make your journey to the airport stress-free and convenient."
      },
      {
        name: "Pune to Shirdi 20 Seater Mini Bus on Rent",
        description: "Heading from Pune to Shirdi? Our 20-seater mini buses are an excellent option for this trip. Equipped with ample space and modern amenities, our mini buses ensure a comfortable and enjoyable journey to the pilgrimage site of Shirdi."
      },
      {
        name: "20 Seater Bus for Corporate Events in Pune",
        description: "Planning a corporate event in Pune? Our 20-seater buses are ideal for transporting employees or clients to and from corporate events. With comfortable seating and professional service, we ensure that your corporate travel needs are met with ease and efficiency."
      },
      {
        name: "20 Seater Tempo Traveller Booking",
        description: "Looking to book a 20-seater tempo traveller? Our booking process is simple and efficient. We offer a range of 20-seater tempo travellers equipped with modern amenities to ensure a comfortable and enjoyable travel experience for your group, regardless of the occasion."
      },
      {
        name: "Non-AC Seater Tempo Traveller in Pune",
        description: "For a more budget-friendly option, consider our non-AC seater tempo travellers in Pune. These vehicles provide comfortable seating and ample space without the added expense of air conditioning, making them a great choice for cost-effective group travel."
      },
      {
        name: "20 Seater Bus on Rent for Wedding in Pune",
        description: "Planning a wedding in Pune and need transportation for your guests? Our 20-seater buses are perfect for wedding transportation. With spacious interiors and comfortable seating, our buses ensure that your guests travel in style and comfort to your special day."
      },
      {
        name: "Pune to Shirdi 20 Seater Bus on Rent",
        description: "Traveling from Pune to Shirdi with a group? Our 20-seater buses offer a comfortable and spacious option for this journey. Enjoy a smooth ride with our well-maintained buses, designed to make your trip to Shirdi both pleasant and enjoyable."
      },
      {
        name: "Pune to Lonavala 20 Seater Bus on Rent",
        description: "Heading to Lonavala from Pune? Our 20-seater bus rental service provides a comfortable and reliable transportation option for your trip. With ample space and modern amenities, our buses are ideal for a relaxing journey to Lonavala."
      },
      {
        name: "20 Seater Tempo Traveller Booking in Pune",
        description: "If you're looking to book a 20-seater tempo traveller in Pune, Shraddha Travels offers a range of options tailored to meet your group travel needs. Our vehicles are ideal for group excursions, corporate events, or family trips, providing a comfortable ride with professional drivers."
      },
      {
        name: "20 Seater Bus On Rent in Pune Airport",
        description: "Traveling to or from Pune Airport? Our 20-seater bus rental service is perfect for airport transfers. With spacious seating and reliable service, our buses ensure that your journey to and from the airport is comfortable and hassle-free. Book with us for a smooth airport experience."
      },
      {
        name: "Pune to Hyderabad Tempo Traveller on Rent",
        description: "Planning a trip from Pune to Hyderabad? Our tempo travellers are an excellent choice for this long-distance journey. With comfortable seating and modern amenities, our 20-seater tempo travellers provide a pleasant and relaxing ride, making your journey to Hyderabad enjoyable."
      },
      {
        name: "Pune to Konkan Darshan Tempo Traveller on Rent",
        description: "Experience the scenic beauty of Konkan with our tempo traveller rental service. Our 20-seater tempo travellers are perfect for a Konkan Darshan trip, offering ample space and comfort. Enjoy picturesque landscapes and explore Konkan with ease in our well-maintained vehicles."
      },
      {
        name: "Mini Bus on Rent in Pune - Shraddha Travels",
        description: "For mini bus rentals in Pune, Shraddha Travels provides a range of options to suit your needs. Our mini buses are ideal for small group travel, corporate outings, and family events. With comfortable seating and reliable service, we ensure a pleasant journey for all passengers."
      },
      {
        name: "20 Seater Bus on Rent in Pimpri Chinchwad",
        description: "In Pimpri Chinchwad and need a 20-seater bus? Shraddha Travels offers competitive rates and reliable service for 20-seater bus rentals in this area. Whether it's for a family gathering, corporate event, or other occasions, our buses provide the space and comfort you need."
      },
      {
        name: "20 Seater Bus on Rent in Pimpri Chinchwad for Family",
        description: "Looking for a 20-seater bus for a family outing in Pimpri Chinchwad? Our buses are perfect for accommodating large families, offering spacious seating and comfort for a memorable journey. Enjoy your family trip with our reliable and well-maintained 20-seater buses."
      },
      {
        name: "Tempo Traveller on Rent in Pimpri Chinchwad",
        description: "If you need a tempo traveller in Pimpri Chinchwad, Shraddha Travels offers various options to meet your requirements. Our tempo travellers are suitable for group travel and come equipped with modern amenities to ensure a comfortable and enjoyable ride."
      },
      {
        name: "20 Seater Bus on Rent in Pune Contact Number",
        description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 20 Seater Bus on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers, making your travel experience hassle-free."
      }
    ],
    
    tableData: [
      ['- 20 Seater Bus on Rent in Pune', '- 20 Seater Bus Rental in Pune for Outstation'],
      ['- 20 Seater Bus Rental in Pimpri Chinchwad', '- 20 Seater Mini Bus Hire in Pimpri Chinchwad'],
      ['- Pune to Mumbai Airport Mini Bus on Rent', '- Pune to Shirdi 20 Seater Mini Bus on Rent'],
      ['- 20 Seater Bus for Corporate Events in Pune', '- 20 Seater Tempo Traveller Booking'],
      ['- Non-AC Seater Tempo Traveller in Pune', '- 20 Seater Bus on Rent for Wedding in Pune'],
      ['- Pune to Shirdi 20 Seater Bus on Rent', '- Pune to Lonavala 20 Seater Bus on Rent'],
      ['- 20 Seater Tempo Traveller Booking in Pune', '- 20 Seater Bus on Rent in Pune Airport'],
      ['- Pune to Hyderabad Tempo Traveller on Rent', '- Pune to Konkan Darshan Tempo Traveller on Rent'],
      ['- Mini Bus on Rent in Pune - Shraddha Travels', '- 20 Seater Bus on Rent in Pimpri Chinchwad'],
      ['- 20 Seater Bus on Rent in Pimpri Chinchwad for Family', '- Tempo Traveller on Rent in Pimpri Chinchwad']
    ],
    
    whychoose: [
      {
        "WhyChooseheading": "Why Choose Shraddha Travels for 20-Seater Bus on Rent in Pune?",
        "WhyChoosedescription": "When it comes to renting a 20-seater bus in Pune, Shraddha Travels stands out as a trusted provider dedicated to ensuring a comfortable and reliable journey. Whether you're planning a family trip, a corporate outing, or a school excursion, our service guarantees that you travel in style and comfort. Here’s a closer look at what makes us the best choice for your group travel needs:"
      },
      {
        "WhyChooseheading": "Spacious and Comfortable Buses:",
        "WhyChoosedescription": "Our 20-seater buses are meticulously designed to provide ample space for passengers, ensuring that everyone enjoys a comfortable ride. The well-cushioned seating and generous legroom allow you to relax, making each journey enjoyable—whether it’s a short city trip or an extended travel adventure."
      },
      {
        "WhyChooseheading": "Modern Amenities:",
        "WhyChoosedescription": "Each bus in our fleet is equipped with modern amenities, including air conditioning, entertainment systems, and charging ports. We believe that traveling should be enjoyable, so we ensure that every ride is not only comfortable but also entertaining, allowing you to enjoy your journey to the fullest."
      },
      {
        "WhyChooseheading": "Well-Maintained Fleet:",
        "WhyChoosedescription": "We place a high priority on the maintenance of our buses to guarantee they are in top-notch condition. Our vehicles undergo regular servicing and thorough inspections, which ensures not only safety and reliability but also a smooth travel experience free from unexpected disruptions."
      },
      {
        "WhyChooseheading": "Experienced and Professional Drivers:",
        "WhyChoosedescription": "Our drivers are highly skilled professionals with extensive experience and knowledge of Pune’s routes as well as outstation destinations. They are committed to providing safe and smooth journeys, always prioritizing your comfort and peace of mind as you travel."
      },
      {
        "WhyChooseheading": "Flexible Rental Options:",
        "WhyChoosedescription": "We understand that every group has unique needs, which is why we offer flexible rental packages tailored to accommodate different requirements and budgets. Whether you need the bus for a few hours, a full day, or several days, we can customize our services to fit your specific plans and preferences."
      },
      {
        "WhyChooseheading": "Competitive and Transparent Pricing:",
        "WhyChoosedescription": "At Shraddha Travels, we pride ourselves on offering competitive rates with absolutely no hidden fees. Our transparent pricing structure ensures that you know exactly what you’re paying for, providing peace of mind and great value for your money while enjoying high-quality service."
      },
      {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with bookings, answer inquiries, and address any issues that may arise. We are committed to ensuring a smooth and hassle-free experience from start to finish, allowing you to focus on enjoying your trip."
      },
      {
        "WhyChooseheading": "On-Time Service:",
        "WhyChoosedescription": "We place a high value on punctuality, understanding the importance of adhering to schedules. Our buses are always on time, enabling you to plan your journey with confidence and ensuring that your travel itinerary runs smoothly without delays."
      },
      {
        "WhyChooseheading": "Hassle-Free Booking Process:",
        "WhyChoosedescription": "Booking a 20-seater bus with us is a simple and efficient process. You can easily reserve your vehicle through our user-friendly website or by contacting our knowledgeable customer support team. We strive to make the booking experience as seamless and convenient as possible for all our clients."
      },
      {
        "WhyChooseheading": "Comprehensive Coverage:",
        "WhyChoosedescription": "Whether you require a bus for local travel within Pune or for outstation trips to popular destinations, Shraddha Travels has you covered. We offer a range of services tailored to various occasions, ensuring you have reliable transportation no matter where your journey takes you."
      }
    ]
      }



      const faqData = [
        {
          question: "How can I book a 20-seater bus with Shraddha Travels?",
          answer: "You can book a 20-seater bus by calling us at +91 8087847191 or through our website. Our team will guide you through the booking process and provide all necessary information."
        },
        {
          question: "What is included in the rental of a 20-seater bus?",
          answer: "Our rental package includes a well-maintained 20-seater bus, an experienced driver, and modern amenities. Additional services or features can be requested and may incur extra charges."
        },
        {
          question: "Are there any hidden fees in the rental cost?",
          answer: "No, we provide transparent pricing with no hidden fees. Any additional costs, such as for extra mileage or extended rental periods, will be clearly communicated before you finalize your booking."
        },
        {
          question: "Can I request an AC or non-AC bus?",
          answer: "Yes, we offer both AC and non-AC options for our 20-seater buses. You can specify your preference at the time of booking to ensure your comfort during the trip."
        },
        {
          question: "What if I need to cancel or change my booking?",
          answer: "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will guide you through the process and inform you of any applicable cancellation fees."
        },
        {
          question: "How are your buses maintained?",
          answer: "Our buses undergo regular inspections and maintenance to ensure they are in excellent condition. We prioritize safety and comfort, so you can travel with peace of mind."
        },
        {
          question: "Can the bus be used for both local and outstation travel?",
          answer: "Yes, our 20-seater buses are suitable for both local travel within Pune and outstation trips. We provide flexible rental options to accommodate different travel needs."
        },
        {
          question: "What amenities are available on the bus?",
          answer: "Our 20-seater buses come equipped with amenities such as air conditioning, comfortable seating, and entertainment systems. Additional features can be requested based on your needs."
        },
        {
          question: "How do you ensure the safety of passengers?",
          answer: "We prioritize safety by employing experienced drivers and maintaining our buses to high standards. Additionally, our drivers are trained to handle various road conditions and ensure a safe journey."
        },
        {
          question: "What should I do if I encounter any issues during the trip?",
          answer: "If you experience any issues during your trip, please contact our customer support team immediately. We are available to address any concerns and ensure a smooth travel experience."
        }
      ];
      


      const testimonials = [
        {
          name: "Miss Anjali Patel",
          text: "I recently hired a 20-seater bus from Shraddha Travels for our company's annual team outing. The entire experience was outstanding. The bus was spacious, comfortable, and well-maintained, which made our journey pleasant and stress-free. The driver was professional and punctual, which was crucial for our tight schedule. The booking process was straightforward, and their customer support team was very helpful throughout. I highly recommend Shraddha Travels for anyone looking for reliable and top-quality bus rentals in Pune."
        },
        {
          name: "Mr. Rajesh Kumar",
          text: "Shraddha Travels provided us with a fantastic 20-seater bus for our family reunion trip. The bus was equipped with modern amenities, including air conditioning and a good sound system, which made our trip enjoyable and comfortable. The driver was courteous and ensured we had a smooth journey from start to finish. The service was exceptional, and the pricing was fair and transparent. We had a great experience and will definitely use Shraddha Travels for our future travel needs."
        }
      ];
      


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 20 Seater Bus on Rent in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 20 Seater Bus on Rent in Pune | Affordable Group Travel</title>
    <meta name="description" content="Rent a 20 Seater Bus in Pune for outstation trips, corporate events, and weddings. Enjoy comfortable travel at competitive rates!" />
    <meta name="keywords" content="20 seater bus on rent in Pune, 20 seater bus rental in Pune for Outstation, 20 seater Mini Bus Hire in Pimpri Chinchwad, Pune to Mumbai Airport Mini Bus on Rent, Pune to Shirdi 20 Seater Mini Bus on Rent, Non AC Seater Tempo Traveller in Pune, 20 Seater Tempo Traveller Booking in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 20 Seater Bus on Rent in Pune | Affordable Group Travel" />
    <meta property="og:description" content="Explore comfortable 20 Seater Bus rentals in Pune for various occasions including weddings and corporate events." />
    <meta property="og:url" content="https://www.shraddhatravels.com/20-seater-bus-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/20-seater-bus-on-rent.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "20 Seater Bus Rental",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/20-seater-bus-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "20 Seater Bus",
                    "description": "Comfortable 20 Seater Bus for outstation trips, corporate events, and weddings."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-08.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs About Renting a 20-Seater Bus in Pune with Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seater20onrent;