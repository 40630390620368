
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Minibusonrent() {



  const cardData =
  {
    keyword: 'Mini Bus on Rent in Pune',
    heading: 'Mini Bus on Rent in Pune: Shraddha Travels',
    headingDescription: 'Are you planning a group outing, corporate event, or family trip? Look no further! Shraddha Travels offers reliable and comfortable mini bus rentals in Pune to cater to all your transportation needs. Our mini buses are ideal for medium-sized groups, providing ample space for passengers and luggage while ensuring a smooth travel experience.',
    top: 'Top Reasons to Rent a Mini Bus from Shraddha Travels in Pune',

    topPlaces: [
        {
          title: "1. Group Travel Convenience",
          description: "Perfect for families, corporate outings, or group tours, a mini bus allows everyone to travel together, making logistics easier. This not only fosters bonding but also reduces the hassle of coordinating multiple vehicles, ensuring a more enjoyable and cohesive travel experience."
        },
        {
          title: "2. Cost-Effective Solution",
          description: "Renting a mini bus can be more economical than multiple cars, saving you on fuel and parking costs. With shared expenses among passengers, the overall travel budget is significantly reduced, allowing for more spending on activities and experiences at your destination."
        },
        {
          title: "3. Comfortable Seating",
          description: "With spacious seating and ample legroom, our mini buses ensure a comfortable ride for all passengers. Each seat is designed for relaxation, making long journeys pleasant and enjoyable, which is especially important during family trips or group adventures."
        },
        {
          title: "4. Customizable Itineraries",
          description: "Tailor your travel plans with flexible pick-up and drop-off points, allowing for a personalized journey. This flexibility means you can visit attractions at your own pace, stop for photos, and adjust your schedule as needed, enhancing your travel experience."
        },
        {
          title: "5. Local Knowledge",
          description: "Our drivers know Pune and the surrounding areas well, helping you navigate traffic and discover the best routes. Their local expertise also means they can recommend hidden gems and popular attractions, ensuring you make the most of your trip."
        },
        {
          title: "6. No Maintenance Worries",
          description: "Renting means you don’t have to worry about vehicle upkeep; we handle all maintenance and servicing. This ensures that the vehicle is always in top condition, providing peace of mind so you can focus on enjoying your journey."
        },
        {
          title: "7. Ideal for Events",
          description: "Whether it's weddings, corporate events, or school trips, our mini buses can accommodate your specific needs, making transportation hassle-free. We understand the importance of punctuality and reliability during events, ensuring that your group arrives on time and in style."
        },
        {
          title: "8. Entertainment Options",
          description: "Many of our mini buses come equipped with entertainment systems, ensuring your journey is enjoyable. Passengers can watch movies, listen to music, or enjoy games, making long drives feel shorter and keeping everyone entertained."
        },
        {
          title: "9. Safety Assurance",
          description: "We prioritize your safety with well-maintained vehicles and trained drivers who follow all safety protocols. Our commitment to safety includes regular inspections and adhering to road regulations, so you can travel with confidence."
        },
        {
          title: "10. Easy Booking Process",
          description: "Our user-friendly booking system makes it simple to reserve your mini bus, with 24/7 support available for any questions. From initial inquiries to final confirmations, our dedicated team ensures a smooth and hassle-free booking experience."
        }
      ],      
      "services": [
        {
          "name": "Mini Bus on Rent in Pune",
          "description": "Looking for a comfortable and convenient mini bus on rent in Pune? Shraddha Travels offers a wide range of mini buses suited for all your travel needs, including family trips, corporate outings, picnics, weddings, and more. Our fleet includes well-maintained buses available for both short and long-distance travel, ensuring you enjoy a hassle-free experience from start to finish."
        },
        {
          "name": "Mini Bus Hire in Pune",
          "description": "Our mini bus hire in Pune service is perfect for groups of various sizes, offering a flexible and cost-effective solution for your transportation needs. Whether you are planning an outstation trip or a local event, we provide professional drivers and affordable rental packages to ensure you travel in comfort and style."
        },
        {
          "name": "Minibus Hire with Driver for Outstation",
          "description": "Enjoy a hassle-free trip with our minibus hire with driver for outstation service. Our experienced drivers ensure you have a safe and smooth journey, no matter the distance. This service is ideal for family vacations, corporate trips, or weekend getaways, allowing you to relax and enjoy the scenery along the way."
        },
        {
          "name": "Mini Bus Rentals for Corporate Trip",
          "description": "Make your corporate outings convenient and comfortable with our mini bus rentals for corporate trips. Our buses are designed for a relaxing ride and equipped with modern amenities to ensure your team travels in comfort and arrives at your destination ready for the day ahead."
        },
        {
          "name": "Small Bus Rental Service in Pune",
          "description": "Our small bus rental service in Pune is the ideal choice for smaller groups. Whether it’s a day trip or an event, our buses offer the perfect balance of affordability, space, and comfort, making it easy for your group to travel together."
        },
        {
          "name": "Minibus Rental Near Me in Pune",
          "description": "Searching for a minibus rental near me in Pune? Shraddha Travels offers conveniently located rental services throughout Pune, ensuring you get a bus that fits your requirements, no matter where you are in the city. Our responsive service makes booking easy and efficient."
        },
        {
          "name": "Mini Bus Rent Per Km",
          "description": "We offer competitive pricing with our mini bus rent per km service, starting from ₹20 to ₹30 per km depending on the model and destination. This allows you to enjoy affordable, comfortable travel without breaking the bank, making it easier to plan your budget."
        },
        {
          "name": "Mini Coach Hire in Pune",
          "description": "For larger groups, consider our mini coach hire in Pune service. Our mini coaches are perfect for big family trips, corporate outings, or special occasions, providing more space and comfort while ensuring that everyone travels together in style."
        },
        {
          "name": "Mini Bus for Rent Near Me in Pune",
          "description": "Need a mini bus for rent near me in Pune? Shraddha Travels is always nearby with a fleet of well-maintained buses for all your transportation needs. Enjoy reliable service and flexible rental options for your journey, making travel planning stress-free."
        },
        {
          "name": "12-Seater Mini Bus on Rent in Pune",
          "description": "Our 12-seater mini bus on rent in Pune is ideal for small groups. Whether you are going on a day trip or a short outstation journey, our 12-seater bus offers the perfect combination of space, comfort, and affordability, ensuring a pleasant travel experience."
        },
        {
          "name": "Mini Bus on Rent Near Me for Outstation",
          "description": "Planning an outstation trip? Our mini bus on rent near me for outstation service is designed to make your journey stress-free. From Pune to any destination, we ensure a comfortable ride with our premium vehicles, equipped to handle your travel needs."
        },
        {
          "name": "Mini Bus for Lonavala Picnic from Pune",
          "description": "Organizing a picnic at Lonavala? Book our mini bus for Lonavala picnic from Pune for a seamless and enjoyable trip. Our buses provide enough room for everyone while ensuring a smooth and scenic journey to your destination, making your outing memorable."
        },
        {
          "name": "Mini Bus Hire for Event in Pune",
          "description": "From weddings to corporate events, our mini bus hire for event in Pune service is tailored to make your special occasions hassle-free. We offer a range of buses to accommodate your guests comfortably, ensuring they arrive in style and on time."
        },
        {
          "name": "Pune to Goa Mini Bus",
          "description": "Travel in comfort with our Pune to Goa mini bus service. Whether it's a family vacation or a group outing, we ensure your trip to Goa is safe and enjoyable with our well-maintained mini buses, ready to enhance your holiday experience."
        },
        {
          "name": "Mini Bus Hire in Pimpri Chinchwad",
          "description": "Shraddha Travels provides mini bus hire in Pimpri Chinchwad for all your local and outstation travel needs. We ensure reliable service, professional drivers, and well-maintained vehicles for a smooth journey, making it easy for you to travel comfortably."
        },
        {
          "name": "Mini Bus Hire in Kharadi Pune",
          "description": "Looking for mini bus hire in Kharadi Pune? Shraddha Travels offers affordable rental packages for Kharadi and nearby areas, ensuring that your transportation needs are met with comfort and convenience for all occasions."
        },
        {
          "name": "Minibus Hire for Wedding Party in Pune",
          "description": "Make your wedding day even more special with our minibus hire for wedding party in Pune. Transport your guests in style with our luxury minibuses, providing a comfortable and elegant ride that enhances your special day."
        },
        {
          "name": "Mini Bus on Rent in Hinjewadi",
          "description": "Need a mini bus on rent in Hinjewadi? Shraddha Travels offers flexible and affordable rental options for local and outstation trips, ensuring your group travels in comfort and arrives at their destination relaxed and ready."
        },
        {
          "name": "Mini Bus on Rent in Baner",
          "description": "Our mini bus on rent in Baner is available for family outings, picnics, and other events. We provide professional drivers and well-maintained buses to ensure a smooth and enjoyable journey for you and your guests."
        },
        {
          "name": "Mini Bus on Rent in Pune Near Me",
          "description": "Searching for a mini bus on rent in Pune near me? Shraddha Travels has got you covered. We offer quick and easy rental options across Pune, ensuring that you always find a bus that meets your travel needs with minimal hassle."
        },
        {
          "name": "Luxury Mini Bus on Rent in Pune Near Me",
          "description": "Travel in style with our luxury mini bus on rent in Pune near me service. Our luxury buses are equipped with modern amenities to make your journey a memorable one, perfect for weddings, corporate trips, or special events that require a touch of elegance."
        },
        {
          "name": "Mini Bus on Rent in Pune for Family",
          "description": "Planning a family trip? Book our mini bus on rent in Pune for family trips and enjoy a comfortable and spacious ride. Whether it's a short local trip or a long outstation journey, we ensure a pleasant travel experience tailored for your family."
        },
        {
          "name": "13, 15, 17, 20, 24, 27-Seater Mini Bus on Rent in Pune",
          "description": "Shraddha Travels offers a variety of seating options, from 13, 15, 17, 20, 24, to 27-seater mini buses on rent in Pune. Whether you're traveling with a small group or a large party, we have the right bus for your needs, ensuring everyone travels comfortably together."
        },
        {
          "name": "Mini Bus on Rent in Pune Contact Number",
          "description": "Contact Shraddha Travels at +91 8087847191 for prompt and efficient mini bus rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
      ],      
      tableData: [
        ['- Mini Bus on Rent', '- Mini Bus Hire in Pune'],
        ['- Minibus Hire with Driver for Outstation', '- Mini Bus Rentals for Corporate Trip'],
        ['- Small Bus Rental Service in Pune', '- Minibus Rental Near Me in Pune'],
        ['- Mini Bus Rent Per Km', '- Mini Coach Hire in Pune'],
        ['- Mini Bus for Rent Near Me in Pune', '- 12-Seater Mini Bus on Rent in Pune'],
        ['- Mini Bus on Rent Near Me for Outstation', '- Mini Bus for Lonavala Picnic from Pune'],
        ['- Mini Bus Hire for Event in Pune', '- Pune to Goa Mini Bus'],
        ['- Mini Bus Hire in Pimpri Chinchwad', '- Mini Bus Hire in Kharadi Pune'],
        ['- Minibus Hire for Wedding Party in Pune', '- Mini Bus On Rent in Hinjewadi'],
        ['- Mini Bus on Rent in Baner', '- Mini Bus on Rent in Pune Near Me'],
        ['- Luxury Mini Bus on Rent in Pune Near Me', '- Mini Bus on Rent in Pune for Family'],
        ['- 13, 15, 17, 20, 24, 27-Seater Mini Bus on Rent in Pune', '']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Mini Bus On Rent in Pune?",
            WhyChoosedescription: "When you need a reliable and comfortable mini bus rental in Pune, Shraddha Travels is your go-to choice. We prioritize your comfort and convenience, making every journey memorable. Here’s why you should choose us:"
        },
        {
            WhyChooseheading: "Diverse Fleet of Mini Buses:",
            WhyChoosedescription: "At Shraddha Travels, we offer a wide selection of mini buses to meet various travel needs. Whether you require a 12-seater for a family outing or a 27-seater for a large event, our fleet has the perfect vehicle equipped with modern amenities."
        },
        {
            WhyChooseheading: "Exceptional Comfort and Luxury:",
            WhyChoosedescription: "Our mini buses are designed for your comfort, featuring plush seating, ample legroom, advanced air conditioning, and entertainment systems. For those seeking extra elegance, we provide luxury mini buses with high-end features."
        },
        {
            WhyChooseheading: "Flexible Rental Options:",
            WhyChoosedescription: "We understand that different trips require unique arrangements. Our flexible rental options include hourly, daily, and long-term rentals, tailored to your specific travel plans."
        },
        {
            WhyChooseheading: "Professional and Experienced Drivers:",
            WhyChoosedescription: "Safety is paramount at Shraddha Travels. Our professional drivers are well-trained and familiar with Pune's routes, ensuring a smooth and secure travel experience, allowing you to relax during your journey."
        },
        {
            WhyChooseheading: "Competitive and Transparent Pricing:",
            WhyChoosedescription: "We offer competitive rates with no hidden fees, providing transparent pricing that helps you plan your travel budget confidently."
        },
        {
            WhyChooseheading: "Convenient Booking Process:",
            WhyChoosedescription: "Our user-friendly online booking system and dedicated customer support team make reserving your mini bus easy and straightforward."
        },
        {
            WhyChooseheading: "Well-Maintained and Clean Vehicles:",
            WhyChoosedescription: "All our mini buses are regularly serviced and cleaned to ensure high hygiene and safety standards, providing you with a reliable travel experience."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our commitment to excellent service extends beyond vehicle provision. Our 24/7 customer support team is always available to assist you with bookings and any travel-related issues."
        },
        {
            WhyChooseheading: "Tailored Solutions for Special Occasions:",
            WhyChoosedescription: "Whether for a corporate event, wedding, or family outing, we offer customized mini bus rental solutions designed to make your special occasion memorable."
        },
        {
            WhyChooseheading: "Trusted by Many:",
            WhyChoosedescription: "With a reputation for reliability and quality, Shraddha Travels is a trusted choice for mini bus rentals in Pune. Our satisfied customers and positive reviews reflect our commitment to delivering exceptional travel experiences."
        }
    ]
    
  }

  const faqData = [
    {
        question: "How do I book a mini bus with Shraddha Travels?",
        answer: "You can book a mini bus by contacting us directly via phone or email, or by using our easy-to-navigate online booking system. Our customer support team will assist you throughout the booking process."
    },
    {
        question: "What types of mini buses are available for rent?",
        answer: "We offer a range of mini buses, including 12-seaters, 15-seaters, 17-seaters, 20-seaters, 24-seaters, and 27-seaters. We also provide luxury options with advanced features for a premium experience."
    },
    {
        question: "Are the mini buses equipped with air conditioning?",
        answer: "Yes, all our mini buses come equipped with advanced air conditioning systems to ensure a comfortable environment, regardless of the weather outside."
    },
    {
        question: "Can I hire a mini bus for an outstation trip?",
        answer: "Absolutely. We offer mini bus rentals for outstation trips, including popular destinations like Goa, Lonavala, and Mahabaleshwar. Our drivers are well-versed with routes to ensure a smooth journey."
    },
    {
        question: "How much does it cost to rent a mini bus?",
        answer: "The cost of renting a mini bus varies depending on the type of vehicle, rental duration, and distance traveled. We provide transparent pricing with no hidden charges. Contact us for a detailed quote based on your specific requirements."
    },
    {
        question: "What is included in the rental price?",
        answer: "Our rental price typically includes the use of the mini bus, driver services, and basic amenities. Additional costs may apply for extra services such as extended mileage or special requests. We’ll provide a clear breakdown of costs when you book."
    },
    {
        question: "Are there any additional charges I should be aware of?",
        answer: "We strive to maintain transparent pricing. Any additional charges, such as for extra stops or extended travel beyond the agreed mileage, will be communicated to you upfront."
    },
    {
        question: "Can I choose the driver for my trip?",
        answer: "We assign experienced and professional drivers to ensure the highest quality service. While you can request specific preferences or requirements, we ensure that all our drivers meet our stringent standards of professionalism and safety."
    },
    {
        question: "What should I do if I need to cancel or modify my booking?",
        answer: "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will assist you with the changes and inform you of any applicable cancellation or modification policies."
    },
    {
        question: "How can I contact Shraddha Travels for support during my journey?",
        answer: "You can reach our 24/7 customer support team by calling us or sending an email. We are here to assist you with any queries or issues that may arise during your journey to ensure a smooth and enjoyable experience."
    }
];

  


const testimonials = [
    {
        name: "Ms. Prajakta Pawar",
        text: "We hired a mini bus from Shraddha Travels for our family reunion, and it was a fantastic experience! The bus was spacious, comfortable, and the driver was friendly and professional. Highly recommend!"
    },
    {
        name: "Ms. Anjali Mehta",
        text: "Shraddha Travels made our corporate outing smooth and enjoyable. The mini bus was well-maintained, and the service was top-notch. I will definitely choose them for future events!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Mini Bus On Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Mini Bus On Rent in Pune | Contact: +91 8087847191 | Shraddha Travels | Affordable Rentals</title>
    <meta name="description" content="Discover reliable mini bus rental services in Pune. Ideal for family trips, corporate events, and outstation travel. Book now!" />
    <meta name="keywords" content="Mini bus on rent in Pune, Mini AC Bus on rent, Mini bus for corporate events, Pune to Shirdi mini bus" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Mini Bus On Rent in Pune | Affordable Rentals" />
    <meta property="og:description" content="Rent mini buses in Pune for family trips, corporate events, and outstation travel. Competitive rates and excellent service!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/mini-bus-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/mini-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Mini Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/mini-bus-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Mini Bus Rental",
                    "description": "Affordable mini bus rentals for family trips, corporate events, and outstation travel in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-04.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs About Mini Bus On Rent in Pune with Shraddha Travels:</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Minibusonrent;