
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetolonavalabushire() {



  const cardData =
  {
    keyword: 'Pune to Lonavala Bus Hire',
    heading: 'Shraddha Travels: Pune to Lonavala Bus Hire',
    headingDescription: 'Are you planning a trip from Pune to Lonavala? Choose Shraddha Travels for a seamless and comfortable journey. Our reliable bus hire service ensures that you travel in style and comfort, with a range of bus options to suit your needs. Whether you are organizing a family outing, corporate trip, or a school excursion, our well-maintained buses are ready to take you to Lonavala, one of Maharashtra’s most charming hill stations.',
    top: 'Top Places to Visit in Lonavala with Shraddha Travels',

    topPlaces: [
        {
          title: "1. Lonavala Lake",
          description: "A tranquil spot perfect for relaxation, Lonavala Lake is surrounded by lush greenery and offers a serene environment. Visitors can enjoy leisurely walks along the lakeside, indulge in a picnic with family and friends, or simply soak in the natural beauty. The picturesque setting makes it an ideal spot for photographers and nature lovers alike."
        },
        {
          title: "2. Karla Caves",
          description: "These ancient Buddhist rock-cut caves are a significant historical and architectural marvel. The Karla Caves feature intricate carvings and sculptures that provide insight into early Buddhist architecture. Visitors can explore the grand Chaitya hall, which houses a massive stupa, and appreciate the skillful craftsmanship that has stood the test of time."
        },
        {
          title: "3. Bhaja Caves",
          description: "Another set of ancient Buddhist rock-cut caves, Bhaja Caves are known for their stunning architecture and historical significance. Nestled on a hill, the caves offer panoramic views of the surrounding landscape. The site features beautifully carved prayer halls and stupas, making it a fascinating destination for history enthusiasts and hikers."
        },
        {
          title: "4. Rajmachi Fort",
          description: "Rajmachi Fort is a popular trekking destination that offers breathtaking views of the Sahyadri mountain range. The fort's ruins tell tales of its rich history, and the trek to the fort is suitable for both novice and experienced trekkers. Along the way, you can enjoy the vibrant flora and fauna, making it a rewarding experience for adventure lovers."
        },
        {
          title: "5. Duke’s Nose",
          description: "Also known as Nagphani, Duke’s Nose is a prominent peak resembling a snake’s hood. It’s a favorite spot for trekking, rock climbing, and photography, offering panoramic views of the surrounding hills and valleys. The trek is invigorating and provides a sense of accomplishment, making it popular among adventure seekers."
        },
        {
          title: "6. Lion’s Point",
          description: "Lion’s Point provides stunning sunset views and is a popular vantage point for capturing the beauty of Lonavala’s landscape. The area is surrounded by lush greenery and offers a picturesque setting for a romantic getaway or a family outing. The cool breeze and breathtaking vistas make it a must-visit spot, especially during the monsoon season."
        },
        {
          title: "7. Pawna Lake",
          description: "An artificial reservoir, Pawna Lake is ideal for picnics, camping, and water activities. Surrounded by hills, it offers opportunities for boating, fishing, and enjoying watersports. The serene environment makes it a great place to unwind, and visitors can often find beautiful camping spots by the water's edge, perfect for stargazing at night."
        },
        {
          title: "8. Tung Fort",
          description: "Tung Fort is a historical fort located at a high altitude, offering a challenging trek for adventure enthusiasts. The fort provides stunning views of the surrounding valleys, other forts, and the picturesque landscape. It’s an excellent spot for trekking groups, and the cooler climate at the top makes the effort worthwhile."
        },
        {
          title: "9. Khandala",
          description: "Just a short drive from Lonavala, Khandala is another beautiful hill station known for its scenic beauty and pleasant weather. Key attractions include the Shraddhanjan Point and Khandala Lake, where visitors can enjoy picnics and nature walks. The area is perfect for weekend getaways, offering a peaceful retreat from city life."
        },
        {
          title: "10. Bhushi Dam",
          description: "A popular spot during the monsoon season, Bhushi Dam is known for its cascading waterfalls and natural beauty. It’s a great place to enjoy the refreshing monsoon showers and scenic surroundings. Visitors can play in the water, enjoy local snacks from nearby stalls, and take in the lush greenery that envelops the area, making it a lively outing for families."
        }
      ],      
      services: [
        {
          name: "Pune to Lonavala Bus Hire",
          description: "Looking for a reliable bus hire service for your journey from Pune to Lonavala? Shraddha Travels offers comfortable and well-maintained buses for group travel, making your trip to Lonavala hassle-free and enjoyable. Our fleet includes various bus sizes to accommodate different group sizes, ensuring everyone travels in comfort. Equipped with modern amenities such as air conditioning, spacious seating, and entertainment systems, our buses provide a seamless travel experience. Whether it’s a family trip, corporate outing, or friends’ getaway, Shraddha Travels guarantees timely service with experienced drivers who know the best routes to Lonavala."
        },
        {
          name: "Tempo Travellers on Rent for Lonavala in Pune",
          description: "Planning a group trip to Lonavala and need a reliable transport solution? Shraddha Travels offers premium tempo travellers on rent for your journey from Pune to Lonavala. Our vehicles are meticulously maintained to ensure comfort and safety, featuring spacious interiors, reclining seats, and modern amenities such as air conditioning and entertainment systems. With experienced drivers who know the best routes and local attractions, your journey will be both enjoyable and efficient."
        },
        {
          name: "Pune to Lonavala Urbania Bus on Rent",
          description: "For a comfortable and luxurious travel experience, choose our Urbania bus on rent for your journey from Pune to Lonavala. These buses are designed with elegance and functionality in mind, equipped with plush seating, ample legroom, and cutting-edge entertainment options. Ideal for corporate trips, family outings, or special events, our Urbania buses ensure you travel in style while enjoying a smooth ride through the scenic landscape."
        },
        {
          name: "Pune to Khandala Bus Hire Package",
          description: "Explore the scenic beauty of Khandala with our exclusive bus hire package. Shraddha Travels provides tailored bus hire packages from Pune to Khandala, ensuring a comfortable and enjoyable journey. Our packages include various bus sizes to suit your group size and travel needs, with professional drivers who offer local insights. Enjoy stops at key attractions, take in the breathtaking views, and create unforgettable memories during your trip."
        },
        {
          name: "Pune to Lonavala 17-Seater Bus Hire Package",
          description: "For smaller groups or intimate gatherings, our 17-seater bus hire package is perfect. The bus is equipped with modern amenities, ensuring a comfortable journey from Pune to Lonavala. With personalized service, our friendly drivers cater to your needs, making sure you enjoy every moment of your trip. Whether it's a family outing or a friends' getaway, we provide a hassle-free travel experience."
        },
        {
          name: "17-Seater Bus Rate Per Km in Pimpri Chinchwad",
          description: "Looking for competitive rates for a 17-seater bus in Pimpri Chinchwad? Shraddha Travels offers transparent pricing based on distance and duration. Our rate per km is designed to provide excellent value for your money, ensuring a cost-effective solution for your travel needs. We prioritize affordability without compromising on quality, giving you peace of mind for your group travels."
        },
        {
          name: "Pune to Lonavala Khandala Bus Tour Package",
          description: "Combine the best of both worlds with our Pune to Lonavala Khandala bus tour package. Shraddha Travels offers a comprehensive tour package that covers major attractions in both Lonavala and Khandala. Enjoy a comfortable and well-organized tour with our experienced drivers, who will take you to stunning viewpoints and local landmarks. Our customized itinerary ensures you get the most out of your journey, making every moment memorable."
        },
        {
          name: "Pune to Lonavala 14, 17, 20, 25, 32-Seater Bus on Rent",
          description: "No matter the size of your group, Shraddha Travels has the right bus for you. We offer a range of buses from 14 to 32-seaters for rent from Pune to Lonavala. Each bus is designed for comfort and convenience, featuring spacious seating and amenities that cater to your group’s needs. Enjoy a stress-free journey with our reliable service and professional drivers, allowing you to focus on your adventure."
        },
        {
          name: "Pune to Lonavala 35, 40, 45, 50-Seater Bus on Rent",
          description: "For larger groups, our 35, 40, 45, and 50-seater buses provide ample space and comfort for your journey from Pune to Lonavala. Shraddha Travels ensures a smooth and enjoyable ride with well-maintained buses equipped with modern amenities, including restrooms and entertainment systems. Our professional drivers are committed to providing a safe and enjoyable experience, allowing you to relax and enjoy the scenic views."
        },
        {
          name: "Pune to Lonavala 2-Day Bus Package",
          description: "Make the most of your trip with our 2-day bus package from Pune to Lonavala. This flexible and affordable package includes comfortable transport and a customized itinerary, allowing you to explore Lonavala’s attractions over two days. Enjoy guided tours, visits to local restaurants, and plenty of opportunities for relaxation, making it a perfect getaway for families and friends alike."
        },
        {
          name: "Pune to Khandala Mini Bus Package",
          description: "If you’re planning a trip to Khandala and need a smaller vehicle, our mini bus package is an ideal choice. Shraddha Travels provides mini buses for rent that are perfect for group travel, offering a cozy environment for your journey. Our mini buses are equipped with comfortable seating and all necessary amenities, ensuring an enjoyable travel experience while you take in the beautiful landscapes."
        },
        {
          name: "Pune to Lonavala Corporate Bus Package",
          description: "For corporate events and team outings, Shraddha Travels offers specialized corporate bus packages from Pune to Lonavala. Our corporate bus packages include comfortable buses with amenities suited for business travel, professional drivers, and flexible options to meet your corporate travel requirements. Whether you need transportation for meetings, conferences, or team-building activities, we ensure a seamless experience."
        },
        {
          name: "Pune to Lonavala Corporate Event Package",
          description: "If you're organizing a corporate event or outing to Lonavala, Shraddha Travels provides tailored packages to ensure your event runs smoothly. Our corporate event packages include comfortable and reliable transportation options that cater to your specific needs, whether for team-building activities, business meetings, or company retreats. We focus on professionalism and punctuality, allowing you to concentrate on your event."
        },
        {
          name: "Pune to Lonavala Bus on Rent",
          description: "Our bus rental service for the Pune to Lonavala route is ideal for group travel. Choose from various bus sizes to accommodate your group comfortably. We offer well-maintained buses equipped to handle long journeys, ensuring a pleasant travel experience. Our drivers are familiar with the route, ensuring timely arrivals and smooth travels."
        },
        {
          name: "Pune to Lonavala Tourist Bus on Rent",
          description: "For a sightseeing adventure from Pune to Lonavala, our tourist buses provide the perfect solution. Our fleet includes buses designed for comfort and convenience, making your journey enjoyable while you explore the scenic beauty of Lonavala. With knowledgeable drivers who can share insights about local attractions, you’ll make the most of your trip."
        },
        {
          name: "Pune to Lonavala Urbania on Rent",
          description: "Experience luxury and style with our Urbania rentals for your trip from Pune to Lonavala. Ideal for high-profile groups or special occasions, the Urbania offers a premium travel experience with added amenities such as reclining seats, entertainment systems, and spacious interiors. Travel in comfort and make a statement as you arrive at your destination."
        },
        {
          name: "Pune to Lonavala Tempo Traveller on Rent",
          description: "Our tempo traveller service for Pune to Lonavala is perfect for both small and large groups. Choose from a range of tempo traveller options to suit your group size and travel preferences. Enjoy a comfortable ride with ample space for luggage and relaxation, ensuring everyone has a pleasant experience as you travel to one of Maharashtra’s most beautiful destinations."
        },
        {
          name: "Pune to Lonavala Full Day Tempo Traveller on Rent",
          description: "If you’re planning a full-day excursion to Lonavala, our full-day tempo traveller rental ensures you have a reliable and comfortable vehicle throughout the day. This option is ideal for day trips where you want the flexibility to explore Lonavala at your own pace, stopping at various attractions and local eateries along the way."
        },
        {
          name: "Mini Bus on Rent for Pune to Lonavala",
          description: "For a more compact group travel option, our mini buses offer a great solution. They provide comfort and ease for smaller groups traveling from Pune to Lonavala, making them perfect for intimate gatherings and small tours. With spacious interiors and essential amenities, you can enjoy your journey while staying connected with your group."
        },
        {
          name: "Tata Winger on Rent Pune to Lonavala",
          description: "Our Tata Winger rentals are a versatile option for your journey from Pune to Lonavala. Known for its comfort and practicality, the Tata Winger is suitable for both corporate events and casual trips. With comfortable seating and ample space for luggage, you can travel with ease while enjoying the stunning views of the Western Ghats."
        },
        {
          name: "One Day Trip from Pune to Lonavala",
          description: "Plan a memorable one-day trip from Pune to Lonavala with Shraddha Travels. Whether you're heading out for sightseeing, adventure, or a quick getaway, we offer a range of vehicles to ensure a comfortable and convenient journey. Our team can help you create a personalized itinerary to explore the best attractions of Lonavala in just one day."
        },
        {
          name: "Shraddha Travels Pune On Rent",
          description: "For all your rental needs from Pune to Lonavala, Shraddha Travels is your trusted partner. We offer a range of vehicles, including buses, tempo travellers, mini buses, and Tata Wingers, all designed to provide a seamless travel experience. Our team is dedicated to ensuring your journey is smooth, comfortable, and enjoyable, with personalized service to meet your unique travel requirements."
        },
        {
          name: "Mini Bus On Rent in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
      ],      
      tableData: [
        ['- Tempo Travellers On Rent For Lonavala in Pune', '- Pune to Lonavala Urbania Bus on Rent'],
        ['- Pune to Khandala Bus Hire Package', '- Pune to Lonavala 17-Seater Bus Hire Package'],
        ['- 17-Seater Bus Rate Per Km in Pimpri Chinchwad', '- Pune to Lonavala Khandala Bus Tour Package'],
        ['- Pune to Lonavala 14, 17, 20, 25, 32-Seater Bus on Rent', '- Pune to Lonavala 35, 40, 45, 50-Seater Bus on Rent'],
        ['- Pune to Lonavala 2-Day Bus Package', '- Pune to Khandala Mini Bus Package'],
        ['- Pune to Lonavala Corporate Bus Package', '- Pune to Lonavala Corporate Event Package'],
        ['- Pune to Lonavala Bus on Rent', '- Pune to Lonavala Tourist Bus on Rent'],
        ['- Pune to Lonavala Urbania on Rent', '- Pune to Lonavala Tempo Traveller on Rent'],
        ['- Pune to Lonavala Full Day Tempo Traveller on Rent', '- Mini Bus on Rent for Pune to Lonavala'],
        ['- Tata Winger on Rent Pune to Lonavala', '- One Day Trip from Pune to Lonavala'],
        ['- Shraddha Travels Pune On Rent', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Us for Pune to Lonavala Bus Hire?",
          WhyChoosedescription: "At Shraddha Travels, we provide an exceptional bus hire service for your journey from Pune to Lonavala. Our commitment to comfort, safety, and customer satisfaction makes us the ideal choice for your travel needs. With a focus on quality and reliability, we ensure that every trip is a memorable experience."
        },
        {
          WhyChooseheading: "Extensive Fleet of Vehicles:",
          WhyChoosedescription: "We offer a diverse range of buses, from 17-seaters for intimate gatherings to 50-seaters for larger groups. Our fleet includes modern buses equipped with the latest amenities to cater to all your travel requirements, ensuring that everyone in your group travels in comfort and style."
        },
        {
          WhyChooseheading: "Comfort and Convenience:",
          WhyChoosedescription: "Our buses feature spacious seating, ample legroom, air conditioning, and modern entertainment systems. We understand that long journeys can be tiring, which is why we prioritize your comfort, allowing you to relax and enjoy the scenic beauty of the Pune to Lonavala route."
        },
        {
          WhyChooseheading: "Experienced and Professional Drivers:",
          WhyChoosedescription: "Our drivers are not only skilled but also familiar with the Pune to Lonavala route. They prioritize your safety and comfort, adhering to all traffic regulations and ensuring a smooth ride. With their extensive knowledge of the area, they can also recommend scenic stops and local attractions."
        },
        {
          WhyChooseheading: "Competitive Pricing:",
          WhyChoosedescription: "We believe that quality travel should be affordable. Our pricing structure is transparent, with no hidden fees, allowing you to plan your budget effectively. We offer various packages to suit different needs, ensuring you get the best value for your money."
        },
        {
          WhyChooseheading: "Flexible Booking Options:",
          WhyChoosedescription: "We understand that every trip is unique, which is why we offer flexible booking options. Whether you need a bus for a few hours or a couple of days, our team can customize our services to fit your itinerary, ensuring a hassle-free experience."
        },
        {
          WhyChooseheading: "Punctual and Reliable Service:",
          WhyChoosedescription: "Timeliness is crucial when it comes to travel. We pride ourselves on our punctuality, ensuring our buses arrive on time and follow your planned schedule. Our reliable service means you can count on us to get you to your destination without delays."
        },
        {
          WhyChooseheading: "Personalized Customer Service:",
          WhyChoosedescription: "Our dedicated customer service team is always available to assist you with any queries or special requests. From the moment you book to the completion of your journey, we ensure that your experience is seamless and satisfactory, addressing any concerns promptly."
        },
        {
          WhyChooseheading: "Clean and Well-Maintained Vehicles:",
          WhyChoosedescription: "We take cleanliness seriously. Our buses are regularly cleaned and maintained to high standards, ensuring they are in excellent condition for your journey. Regular inspections and servicing mean you can travel with peace of mind knowing that your vehicle is safe and reliable."
        },
        {
          WhyChooseheading: "Safe and Secure Travel:",
          WhyChoosedescription: "Safety is our top priority. Our buses are equipped with advanced safety features, and our drivers undergo rigorous training to handle various road conditions. We strive to create a secure environment for all passengers, allowing you to focus on enjoying your trip."
        },
        {
          WhyChooseheading: "Positive Customer Feedback:",
          WhyChoosedescription: "Over the years, we have built a strong reputation for reliability and exceptional service. Our clients consistently praise our attention to detail and commitment to quality. We actively seek feedback to continuously improve our services, ensuring that we meet and exceed your expectations."
        }
      ]
      
  }



  const faqData = [
    {
      question: "How can I book a bus from Pune to Lonavala with Shraddha Travels?",
      answer: "You can book a bus by visiting our website, calling our customer service hotline, or visiting our office in person. We offer easy and convenient booking options to suit your preferences."
    },
    {
      question: "What types of buses are available for the Pune to Lonavala route?",
      answer: "We offer a range of buses, including 17-seaters, 32-seaters, 35-seaters, 40-seaters, and 50-seaters. Choose the bus size that best fits your group size and travel needs."
    },
    {
      question: "Are there any special amenities provided on the buses?",
      answer: "Yes, our buses are equipped with comfortable seating, air conditioning, and other amenities to enhance your travel experience. The specific amenities may vary depending on the bus type."
    },
    {
      question: "What is the cost of hiring a bus from Pune to Lonavala?",
      answer: "The cost varies depending on factors such as the size of the bus, duration of the hire, and any additional services you may require. Contact us for a customized quote based on your specific needs."
    },
    {
      question: "Can I hire a bus for a day trip or multiple days?",
      answer: "Yes, we offer flexible booking options for both day trips and extended journeys. You can choose the duration that best suits your travel plans."
    },
    {
      question: "Are your drivers experienced and reliable?",
      answer: "Yes, our drivers are experienced, professional, and well-versed with the Pune to Lonavala route. They are committed to ensuring a safe and smooth journey."
    },
    {
      question: "How far in advance should I book a bus?",
      answer: "We recommend booking as early as possible to secure your preferred bus and date. Early booking also helps in better planning and availability."
    },
    {
      question: "What is your cancellation policy?",
      answer: "Our cancellation policy includes specific terms and conditions, with potential charges based on the timing of the cancellation. Please contact our customer service team for detailed information."
    },
    {
      question: "Are the buses cleaned and maintained regularly?",
      answer: "Yes, we ensure that all our buses are thoroughly cleaned and regularly maintained to provide a comfortable and hygienic travel experience."
    },
    {
      question: "Can I request additional services or special requirements?",
      answer: "Yes, we can accommodate special requests or additional services based on availability. Please inform us of any specific needs when making your booking."
    }
  ];
  


  const testimonials = [
    {
      name: "Mrs. Priya Sharma",
      text: "Our trip from Pune to Lonavala with Shraddha Travels was fantastic! We hired a 32-seater bus for a family reunion, and the experience was excellent from start to finish. The bus was clean, comfortable, and well-equipped with amenities that made the journey pleasant for everyone. The driver was professional and friendly, ensuring we arrived on time and safely. The booking process was straightforward, and the customer service was top-notch. I highly recommend Shraddha Travels for anyone looking to hire a bus for a trip to Lonavala. We will definitely use their services again!"
    },
    {
      name: "Mr. Sandeep Kulkarni",
      text: "I recently booked a 50-seater bus from Pune to Lonavala for a corporate outing with Shraddha Travels. The entire experience was smooth and hassle-free. The bus was spacious and well-maintained, providing a comfortable ride for our team. The driver was punctual, courteous, and knowledgeable about the best routes. The service exceeded our expectations, and the whole team was very satisfied with the journey. If you’re looking for reliable and comfortable bus hire for a trip to Lonavala, Shraddha Travels is the way to go!"
    }
  ];
  


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Lonavala Bus Hire Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Pune to Lonavala Bus Hire | Contact: +91 8087847191 | Shraddha Travels | Luxury & Affordable Options</title>
    <meta name="description" content="Affordable bus rental services from Pune to Lonavala, including 14, 17, 20, 25, 32, 35, 40, 45, and 50 seater options for corporate events, day trips, and tours." />
    <meta name="keywords" content="Pune to Lonavala Bus Hire, Tempo Traveller for Lonavala, Lonavala Bus Package, Corporate Bus Hire Lonavala, Mini Bus Rental Pune to Lonavala, Luxury Bus Hire Pune to Lonavala" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Lonavala Bus Hire | Luxury & Affordable Options" />
    <meta property="og:description" content="Reliable bus rental services from Pune to Lonavala for family trips, corporate outings, and tourist packages." />
    <meta property="og:url" content="https://www.amuratatravels.com/pune-to-lonavala-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/lonavala-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Lonavala Bus Hire",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/pune-to-lonavala-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services from Pune to Lonavala",
                    "description": "Affordable and reliable bus rental services from Pune to Lonavala, including options for corporate trips and tourist packages."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -13.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for “Pune to Lonavala Bus Hire” – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetolonavalabushire;