
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetoshanishingnapur() {



  const cardData =
  {
    keyword: ' Pune to Shani Shingnapur Mini Bus on Rent',
    heading: 'Shraddha Travels: Pune to Shani Shingnapur Mini Bus on Rent',
    headingDescription: 'If you are seeking a divine escape from Pune, consider a trip to Shani Shingnapur with Shraddha Travels. Our mini bus rental service ensures a comfortable and convenient journey to one of Maharashtra is most revered pilgrimage destinations.',
    top: 'Top Places to Visit in Shani Shingnapur with Shraddha Travel',

    topPlaces: [
        {
          title: "1. Shani Shingnapur Temple",
          description: "The heart of the village, this temple is dedicated to Lord Shani, the deity of justice and karma. The temple features a striking black stone idol that is revered by thousands of devotees who visit to seek blessings for protection and prosperity. The atmosphere is vibrant, especially during festival times, with rituals and chants echoing through the air."
        },
        {
          title: "2. Shani Deol",
          description: "Adjacent to the main temple, Shani Deol is a sacred site where devotees gather to perform special rituals and offerings. This tranquil spot is believed to amplify blessings for peace and prosperity, making it a vital place for personal and community prayers. The rituals often include lighting oil lamps and offering prayers, creating a spiritually enriching experience."
        },
        {
          title: "3. Shani Shingnapur Village",
          description: "Explore the unique charm of Shani Shingnapur village, renowned for its distinctive architecture characterized by homes without doors. This feature is rooted in the local belief that Lord Shani protects the residents from theft and harm. Walking through the village offers a glimpse into the lives of the residents, showcasing their unwavering faith and community spirit."
        },
        {
          title: "4. Maharashtra Mandir",
          description: "Maharashtra Mandir stands as a serene haven for meditation and prayer. The temple's architecture combines traditional and contemporary styles, making it visually stunning. It is not only a place of worship but also a center for spiritual gatherings, providing an ideal atmosphere for reflection and inner peace away from the bustling village life."
        },
        {
          title: "5. Gadadpuri",
          description: "Located nearby, Gadadpuri is an ancient pilgrimage site filled with historical temples and serene surroundings. It's a perfect spot for those looking to immerse themselves in spirituality and tranquility. The peaceful environment, combined with the ancient architecture of the temples, makes it a great location for meditation and contemplation."
        },
        {
          title: "6. Lord Hanuman Temple",
          description: "This nearby temple dedicated to Lord Hanuman is a favorite among worshippers seeking strength and protection. The temple is often bustling with devotees offering prayers, especially on Tuesdays, which are considered auspicious for Hanuman worship. The vibrant energy of the temple and its community creates a welcoming atmosphere for all visitors."
        },
        {
          title: "7. Brahmdev Mandir",
          description: "Dedicated to Brahmdev, this temple offers a calm and serene ambiance ideal for spiritual seekers. Visitors can witness local rituals and participate in the worship practices, gaining insight into the region's rich spiritual traditions. The temple is a great place for quiet reflection, allowing visitors to connect with their inner selves."
        },
        {
          title: "8. Local Markets",
          description: "Vibrant local markets near the temple offer a treasure trove of religious artifacts, offerings, and unique handicrafts. Strolling through the market, you can find everything from traditional prayer items to beautiful souvenirs. Engaging with local artisans provides a glimpse into the craftsmanship of the region and is a wonderful way to support the community."
        },
        {
          title: "9. Sai Baba Mandir",
          description: "Another peaceful spot dedicated to Sai Baba, this temple offers a tranquil environment for prayer and contemplation. The temple attracts many devotees who come to seek blessings and solace. The atmosphere is filled with devotion, making it an ideal place to reflect and connect with the teachings of Sai Baba."
        },
        {
          title: "10. Nearby Waterfalls",
          description: "If time permits, don't miss the opportunity to explore the scenic waterfalls surrounding Shani Shingnapur. These natural wonders, particularly stunning during the monsoon season, offer picturesque picnic spots where you can relax and enjoy the beauty of nature. The sound of cascading water and the lush greenery provide a perfect backdrop for a refreshing day out."
        }
      ],      
      "services": [
        {
          "name": "Mini Bus from Pune to Shani Shingnapur",
          "description": "Our mini bus service is specifically designed to accommodate groups of various sizes, making it perfect for family gatherings, religious pilgrimages, or outings with friends. Each vehicle features spacious interiors, comfortable seating, and modern amenities like air conditioning and entertainment systems, ensuring a smooth and enjoyable ride from Pune to Shani Shingnapur. With our friendly and professional drivers, you can sit back and relax as you embark on your spiritual journey."
        },
        {
          "name": "Pune to Shani Shingnapur Mini Bus per KM",
          "description": "We offer competitive rates for our mini bus service, charged per kilometer, making it easy to estimate your travel costs. Our transparent pricing structure means there are no hidden fees, allowing you to plan your trip with confidence. We aim to provide excellent value for your money while maintaining the highest standards of service and comfort, ensuring your journey is as enjoyable as possible."
        },
        {
          "name": "Affordable Pune Shani Shingnapur Mini Bus",
          "description": "At Shraddha Travels, we believe that quality travel should be accessible to everyone. Our mini bus rates are designed to fit a variety of budgets without compromising on comfort or service. Enjoy a hassle-free journey to one of Maharashtra's most revered sacred sites, knowing that you are receiving reliable service at a price that suits your financial plans."
        },
        {
          "name": "Pune Temple to Shani Shingnapur Mini Bus",
          "description": "Our mini bus service is ideal for those looking to visit multiple temples in Pune before heading to Shani Shingnapur. We can arrange a comprehensive travel experience that not only covers your spiritual destinations but also allows for stops along the way for meals or sightseeing. This seamless travel solution ensures that you can fully immerse yourself in your spiritual journey without any logistical worries."
        },
        {
          "name": "Pune to Shani Shingnapur Fare",
          "description": "The fare for traveling from Pune to Shani Shingnapur varies based on factors such as vehicle type, distance covered, and specific travel requirements. For a customized quote that fits your group's needs, please contact us directly. Our goal is to provide you with the best value for your trip, ensuring that you can focus on your pilgrimage while we take care of the transportation."
        },
        {
          "name": "Pune to Shani Shingnapur Round Trip Mini Bus",
          "description": "If you're planning a round trip, our mini bus service offers flexibility for both one-way and round trip journeys. We understand that travel plans can change, and we're here to accommodate your needs. With our well-maintained vehicles and professional drivers, you can expect a comfortable and timely ride to and from Shani Shingnapur, allowing you to make the most of your pilgrimage."
        },
        {
          "name": "Pune to Shingnapur Cab",
          "description": "For those who prefer a more private option, we offer cab services from Pune to Shani Shingnapur. Our cabs are perfect for couples or small groups, providing a personalized travel experience. Our experienced drivers are well-versed in the best routes, ensuring a safe and pleasant ride while you focus on your spiritual journey, whether it’s your first visit or a familiar pilgrimage."
        },
        {
          "name": "Pune to Shani Shingnapur Taxi Price",
          "description": "If you opt for our taxi services, you can expect competitive and transparent pricing. We provide clear fare structures upfront, so you know exactly what to expect. This clarity simplifies your travel planning, allowing you to allocate your budget effectively for your pilgrimage without worrying about unexpected costs."
        },
        {
          "name": "Pune to Shingnapur Mini Bus",
          "description": "Our fleet of well-maintained mini buses is equipped with modern amenities, such as reclining seats, air conditioning, and entertainment options to make your journey enjoyable. Whether you are traveling for a religious pilgrimage or cultural exploration, Shraddha Travels has the right vehicle to meet your needs, ensuring your comfort throughout the journey."
        },
        {
          "name": "Mini Bus from Pune to Shani Shingnapur",
          "description": "Choose Shraddha Travels for your mini bus rental needs from Pune to Shani Shingnapur. Our commitment to customer satisfaction, comfort, and affordability makes us the preferred choice for travelers. We pride ourselves on our reliable service and attention to detail, ensuring that your experience with us is seamless from the moment you book until you reach your destination."
        },
        {
          "name": "Pune to Shani Shingnapur Mini Bus on Rent Contact Number",
          "description": "For prompt and efficient mini bus rentals from Pune to Shani Shingnapur, contact Shraddha Travels at +91 8087847191. Our dedicated team is ready to assist you with your booking and provide any additional information you may need. We prioritize customer satisfaction and aim to ensure that your ride is smooth and enjoyable, allowing you to focus on your spiritual journey."
        }
      ],      
      tableData: [
        ['- Pune to Shani Shingnapur Mini Bus', '- Mini Bus from Pune to Shani Shingnapur'],
        ['- Pune to Shani Shingnapur Mini Bus per KM', '- Affordable Pune Shani Shingnapur Mini Bus'],
        ['- Pune Temple to Shani Shingnapur Mini Bus', '- Pune to Shani Shingnapur Fare'],
        ['- Pune to Shani Shingnapur Round Trip Mini Bus', '- Pune to Shani Shingnapur Cab'],
        ['- Pune to Shani Shingnapur Taxi Price', '- Pune to Shingnapur Mini Bus'],
        ['- Mini Bus from Pune to Shani Shingnapur', '- Pune to Shani Shingnapur Mini Bus']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Pune to Shani Shingnapur Mini Bus on Rent?",
          WhyChoosedescription: "When it comes to renting a mini bus for your journey from Pune to Shani Shingnapur, Shraddha Travels stands out as a trusted provider for a comfortable and reliable experience. Our commitment to customer satisfaction and quality service ensures that whether you're planning a pilgrimage or a group outing, you travel in style and comfort. We take pride in our attention to detail and strive to make your journey memorable. Here's why Shraddha Travels is the best choice for your trip:"
        },
        {
          WhyChooseheading: "Comfortable and Spacious Vehicles:",
          WhyChoosedescription: "Our mini buses are designed to provide maximum comfort, featuring spacious seating with generous legroom, ensuring that you can stretch out and relax. Equipped with modern amenities like air conditioning, plush seats, and entertainment systems, we create an environment that enhances your travel experience. Whether you're embarking on a long journey or a short trip, you can count on our vehicles to keep you comfortable throughout."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "We believe that quality travel should be accessible to everyone. That’s why we offer competitive rates for our mini bus services, allowing you to enjoy a premium experience without breaking the bank. Our transparent pricing model means no hidden charges or surprises, so you can budget your trip confidently. We regularly review our rates to ensure they remain competitive while maintaining high service standards."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our professional drivers are not only skilled but also knowledgeable about the routes to Shani Shingnapur and other key locations. They prioritize your safety and comfort, navigating traffic smoothly while adhering to all safety regulations. With their friendly demeanor, our drivers enhance your travel experience, providing assistance and guidance as needed, making your journey stress-free."
        },
        {
          WhyChooseheading: "Customizable Packages:",
          WhyChoosedescription: "We recognize that every journey is unique, which is why we offer customizable travel packages. Whether you have a specific itinerary in mind or need assistance in planning your trip, our team will work with you to tailor the experience to fit your group size and preferences. From additional stops along the way to specific departure times, we aim to meet your needs for a personalized travel experience."
        },
        {
          WhyChooseheading: "Punctuality Guaranteed:",
          WhyChoosedescription: "We value your time as much as you do. Our commitment to punctuality means you can count on us for timely departures and arrivals, ensuring that you don’t miss any important rituals or events during your pilgrimage. Our drivers plan their routes carefully to avoid delays, allowing you to enjoy your journey without any worries about being late."
        },
        {
          WhyChooseheading: "24/7 Customer Support:",
          WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist you with bookings, inquiries, and any concerns. Whether you have questions before your trip or need assistance during your journey, we ensure a seamless travel experience from start to finish. Our friendly staff is always ready to help you with any information or changes you might need."
        },
        {
          WhyChooseheading: "Clean and Well-Maintained Fleet:",
          WhyChoosedescription: "Hygiene and safety are our top priorities. Our mini buses undergo regular maintenance and thorough cleaning to ensure they are in excellent condition for your journey. We conduct regular inspections to address any issues promptly, providing you with a reliable and safe travel environment. You can embark on your pilgrimage knowing that you are traveling in a clean, well-maintained vehicle."
        },
        {
          WhyChooseheading: "Easy Booking Process:",
          WhyChoosedescription: "With our user-friendly booking system, reserving your mini bus is quick and hassle-free. You can easily book online, over the phone, or visit our office in Pune to make arrangements. Our straightforward process allows you to select your vehicle, customize your travel package, and finalize your booking in just a few minutes. We strive to make the planning process as smooth as your journey."
        }
      ]
        }


        const faqData = [
            {
              question: "What is the capacity of your mini buses?",
              answer: "Our mini buses come in various sizes, accommodating groups from 10 to 30 passengers, making them ideal for family trips, corporate outings, or pilgrimages."
            },
            {
              question: "How do I book a mini bus from Pune to Shani Shingnapur?",
              answer: "You can easily book a mini bus by contacting us through our website, phone, or by visiting our office. Provide your travel details, and our team will assist you with the booking process."
            },
            {
              question: "Are the drivers experienced?",
              answer: "Yes, all our drivers are experienced, professional, and well-versed with the routes to ensure a safe and comfortable journey."
            },
            {
              question: "What amenities are included in the mini bus?",
              answer: "Our mini buses are equipped with air conditioning, comfortable seating, luggage storage, and in some cases, entertainment options. We ensure a pleasant travel experience."
            },
            {
              question: "Is there a minimum rental period for the mini bus?",
              answer: "Typically, our minimum rental period is one day, but this may vary depending on your specific travel needs. Please confirm during the booking process."
            },
            {
              question: "Can we make multiple stops during our journey?",
              answer: "Absolutely! You can discuss your planned stops with our team when booking, and we’ll accommodate your requests accordingly."
            },
            {
              question: "Are there any hidden charges in the pricing?",
              answer: "No, we believe in transparent pricing. Any additional costs, such as tolls or parking fees, will be communicated upfront."
            },
            {
              question: "How far in advance should I book my mini bus?",
              answer: "We recommend booking at least 1-2 weeks in advance, especially during peak seasons, to ensure availability and secure the best rates."
            },
            {
              question: "What safety measures do you take?",
              answer: "Safety is our top priority. Our vehicles are regularly serviced and sanitized, and our drivers follow all safety protocols to ensure a secure journey."
            },
            {
              question: "Can I modify or cancel my booking?",
              answer: "Yes, you can modify or cancel your booking by contacting our customer service. Please note that cancellation fees may apply depending on the timing of the cancellation."
            }
          ];
          


          const testimonials = [
            {
              name: "Mr. Ramesh P.",
              text: "Our family trip to Shani Shingnapur was a memorable experience, thanks to Shraddha Travels! We rented a mini bus, and the entire process was seamless. The bus was spacious and comfortable, making our journey enjoyable. Our driver was friendly and knowledgeable about the routes, ensuring we arrived safely and on time. I highly recommend Shraddha Travels for anyone looking to rent a mini bus for a pilgrimage or any group travel!"
            },
            {
              name: "Ms. Anjali K.",
              text: "I recently hired a mini bus from Pune to Shani Shingnapur with Shraddha Travels, and I couldn't be happier! The booking process was straightforward, and the staff was very helpful. The mini bus was well-maintained and equipped with all the necessary amenities. Our driver was punctual and professional, making the trip relaxing for everyone. Thank you, Shraddha Travels, for providing such excellent service. We will definitely book again for our next outing!"
            }
          ];
          

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Shani Shingnapur Mini bus on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Ajanta Ellora Tempo Traveller on Rent | Affordable Travel Solutions</title>
    <meta name="description" content="Rent a comfortable Tempo Traveller for your trip from Pune to Ajanta Ellora. Enjoy affordable rates, AC options, and a smooth journey for group travel." />
    <meta name="keywords" content="Pune to Ajanta Ellora Tempo Traveller on Rent, Tempo Traveller Hire Pune to Ajanta Ellora, Rent Tempo Traveller Pune, Affordable Tempo Traveller Rental Pune, AC Tempo Traveller for Ajanta Ellora, Pune to Ajanta Ellora Group Travel" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Ajanta Ellora Tempo Traveller on Rent | Affordable Travel Solutions" />
    <meta property="og:description" content="Book a Tempo Traveller from Pune to Ajanta Ellora at affordable rates. Comfortable AC options available for group trips and sightseeing." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-ajanta-ellora-tempo-traveller-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/ajanta-ellora-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller Rental from Pune to Ajanta Ellora",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-ajanta-ellora-tempo-traveller-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller",
                    "description": "Rent an AC Tempo Traveller from Pune to Ajanta Ellora for a comfortable and scenic trip."
                }
            }
        }
        `} 
    </script>
</Helmet>



      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-07.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs – Pune to Shani Shingnapur Mini Bus on Rent</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoshanishingnapur;